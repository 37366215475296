import { Body4, Body5 } from "client/src/components/Typography/Typography";
import { useMediaQuery } from "client/src/hooks/useMediaQuery";
import clsx from "clsx";
import moment from "moment";

import * as styles from "./legalFooter.module.less";

const legalCopyYear = `© ${moment().format("YYYY")}`;

export function YourBenefitsExplorerLegalFooter() {
  const isPrint = useMediaQuery("print");
  return (
    <div className={styles.legalFooterContainer}>
      <div className={clsx(styles.content, "p-32")}>
        <div className={styles.flex}>
          <div className={styles.text}>
            <Body5 as="p" white={!isPrint}>
              {legalCopyYear} Sun Life Assurance Company of Canada, Wellesley Hills, MA 02481. All
              rights reserved. The Sun Life name and logo are registered trademarks.
            </Body5>
          </div>

          {!isPrint && (
            <div className={styles.links}>
              <Link href="https://www.sunlife.com/sl/pslf-united-states/en/legal/?vgnLocale=en_US">
                Legal
              </Link>

              <Link href="https://www.sunlife.com/sl/pslf-united-states/en/privacy/?vgnLocale=en_US">
                Privacy
              </Link>

              <Link href="https://www.sunlife.com/sl/pslf-united-states/en/security/?vgnLocale=en_US">
                Security
              </Link>

              <Link href="https://www.sunlife.com/sl/pslf-united-states/en/fraud/?vgnLocale=en_US">
                Fraud
              </Link>
            </div>
          )}
        </div>
        <div className={styles.flex}>
          <div className={styles.text}>
            <Body5 as="p" white={!isPrint}>
              GGWC-EE-10777
            </Body5>
          </div>
          <Body5 as="p" white={!isPrint}>
            #1478905838
          </Body5>
        </div>
      </div>
    </div>
  );
}

type LegalLinkProps = {
  href: string;
  children: React.ReactNode;
};

const Link = ({ href, children }: LegalLinkProps) => {
  const isPrint = useMediaQuery("print");
  return (
    <a href={href} target="_blank" rel="noreferrer noopener">
      <Body4 white={!isPrint}>{children}</Body4>
      <span className="sr-only">(opens in a new tab)</span>
    </a>
  );
};
