import { useQueryClient } from "@tanstack/react-query";
import { invalidateDEIFChanges } from "client/src/hooks/changeLogs";
import { jsonContactToContact } from "client/src/hooks/contact";
import { jsonQPSClassToQPSClass } from "client/src/hooks/qps";
import { useSlobMutation, useSlobQuery } from "client/src/hooks/query";
import { useMemo } from "react";
import { assertIsDefined } from "shared/utils/utils";
import type { JsonToTypeMapper, ResponseError } from "client/src/hooks/query";
import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type { ExplorerPageBenefit, PlanType } from "shared/types/ExplorerPageBenefit";
import type { Plan, UpdatePlanInput } from "shared/types/Plan";

export const jsonPlanToPlan: JsonToTypeMapper<Plan> = (plan) => ({
  ...plan,
  payeeListedOnTheCheckContact: plan.payeeListedOnTheCheckContact
    ? jsonContactToContact(plan.payeeListedOnTheCheckContact)
    : null,
  qpsClasses: plan.qpsClasses.map((qpsClass) => jsonQPSClassToQPSClass(qpsClass)),
  createdAt: new Date(plan.createdAt),
  updatedAt: new Date(plan.updatedAt),
  deletedAt: plan.deletedAt ? new Date(plan.deletedAt) : null,
});

export const useGetPlansByClientId = (clientId: string) => {
  return useSlobQuery<Plan[]>({
    method: "get",
    path: `/api/clients/${clientId}/plans`,
    map: (plans) => plans.map(jsonPlanToPlan),
  });
};

export type UpdatePlansPayloadInput = {
  plans: UpdatePlanInput[];
};

export type UpdatePlansQuery = Pick<
  ReturnType<typeof useUpdatePlans>,
  "isPending" | "isError" | "error" | "mutateAsync"
>;

export const useUpdatePlans = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<
    UpdatePlansPayloadInput,
    Plan[],
    "/api/clients/:clientId/plans",
    ResponseError,
    unknown,
    { isPFMLContributionsUpdate?: boolean }
  >({
    method: "put",
    path: `/api/clients/:clientId/plans`,
    options: {
      onSuccess: async function (_data, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}/plans`] }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
          // Plan updates can update contact as well, and contacts are attached to clients
          // so both also need to be invalidated.
          queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}/contacts`] }),
          queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}`] }),
        ]);
      },
    },
  });
};

interface GroupByFields {
  benefitType: BenefitTypeBenEx;
  planType: PlanType;
}

type GroupedPlans = ExplorerPageBenefit[][];

interface GroupedPlansReturn {
  groupedPlans: GroupedPlans;
  ungroupedPlans: ExplorerPageBenefit[];
}

export const useGroupedPlans = ({
  plans,
  groupBy = [{ benefitType: "DENTAL", planType: "PPO" }],
  dentalPlansCombinedFeatureToggle,
}: {
  plans: ExplorerPageBenefit[];
  groupBy?: GroupByFields[];
  dentalPlansCombinedFeatureToggle?: boolean;
}) => {
  return useMemo<GroupedPlansReturn>(() => {
    if (!dentalPlansCombinedFeatureToggle) return { groupedPlans: [], ungroupedPlans: plans };
    const groupedPlans: GroupedPlans = [];
    let ungroupedPlans: ExplorerPageBenefit[] = [];

    // the combination of fields we want to group on, for each grouping
    const targetGroupKeys = groupBy.map((g) => `${g.benefitType}${g.planType}`);

    // convert plans to a map with keys being "{planType}{benefitType}_{carrierName}"
    // we're adding all plans to the map to help preserve original sort order, not just the ones we want to group
    const groupedPlansMap = new Map<string, ExplorerPageBenefit[]>();
    plans.forEach((plan) => {
      const groupingKey = `${plan.benefitType}${plan.planType}_${plan.carrier?.carrierName}`;
      groupedPlansMap.set(groupingKey, [...(groupedPlansMap.get(groupingKey) ?? []), plan]);
    });

    // for keys we want to group by - assign to groupedPlans if containing more than one plan
    // otherwise assign to "ungroupedPlans"
    groupedPlansMap.forEach((grouping, key) => {
      const splitKey = key.split("_");
      assertIsDefined(splitKey[0], "splitKey[0]");
      if (targetGroupKeys.includes(splitKey[0]) && grouping.length > 1)
        groupedPlans.push([...grouping]);
      else ungroupedPlans = [...ungroupedPlans, ...grouping];
    });

    return { groupedPlans, ungroupedPlans };
  }, [dentalPlansCombinedFeatureToggle, groupBy, plans]);
};
