import * as Sentry from "@sentry/react";
import { ChildPageSkeleton } from "client/src/components/ChildPageLayout/ChildPageLayout";
import { BoundaryError } from "client/src/components/Error/BoundaryError";
import { LegalFooter } from "client/src/components/LegalFooter/LegalFooter";
import { YourBenefitsExplorerLegalFooter } from "client/src/components/LegalFooter/YourBenefitsExplorerLegalFooter";
import { Loading } from "client/src/components/Loading/Loading";
import { SupportFooter } from "client/src/components/SupportFooter/SupportFooter";
import { QPSPushProgressOverlay } from "client/src/domain/EIF/QPSPushProgressOverlay/QPSPushProgressOverlay";
import { EmailSentNotification } from "client/src/domain/Email/EmailSentNotification";
import { useBenExCustomBranding } from "client/src/hooks/ExplorerPage/useBenExCustomBranding";
import { WebSocketApp } from "client/src/hooks/useWebSocket";
import { EIFStepPageLoading } from "client/src/pages/EIFStepPage/EIFStepPageLoading";
import { EIFSummarySkeleton } from "client/src/pages/EIFSummaryPage/EIFSummaryWrapper";
import { ExplorerPageIndex } from "client/src/pages/ExplorerPages/ExplorerPageIndex";

import { AnalyticsPage } from "client/src/pages/Navigation/AnalyticsPage";
import { NavBarPage } from "client/src/pages/Navigation/NavBarPage";
import { AuthRedirectHandler } from "client/src/pages/Security/AuthRedirectHandler";
import { isYourBenefitsExplorer } from "client/src/utils/benefits-explorer";
import { lazyBundler } from "client/src/utils/lazyBundler";
import clsx from "clsx";
import React, { Suspense } from "react";
import { RouterProvider, Outlet, createBrowserRouter } from "react-router";

import { RouteData } from "shared/config/routeData";

import * as styles from "./pages/Navigation/navigation.module.less";
import type { RouteObject } from "react-router";

// Auth
const LoginPage = React.lazy(() => import("./pages/Login/LoginPage"));
const ForgotPasswordPage = React.lazy(() => import("./pages/Login/ForgotPasswordPage"));
const ActivatePage = React.lazy(() => import("./pages/Login/ActivatePage"));
const ReActivatePage = React.lazy(() => import("./pages/Login/ReActivatePage"));
const ResetPage = React.lazy(() => import("./pages/Login/ResetPage"));
const TermsOfUse = React.lazy(() => import("./pages/TermsOfUse"));

// Misc.
const NotFoundPage = React.lazy(() => import("./pages/404"));
const MaintenancePage = React.lazy(() => import("./pages/MaintenancePage"));

// Index
const IndexPage = React.lazy(() => import("./pages/IndexPage"));

// Internal Users
const lazyInternalsBundle = lazyBundler(() => import("./Internal.Bundle"));

const ClientsPage = React.lazy(lazyInternalsBundle((b) => b.ClientsPage));
const BenAdminsPage = React.lazy(lazyInternalsBundle((b) => b.BenAdminsPage));
const BenAdminClientsPage = React.lazy(lazyInternalsBundle((b) => b.BenAdminClientsPage));
const BenAdminDetailPage = React.lazy(lazyInternalsBundle((b) => b.BenAdminDetailPage));
const BrokersPage = React.lazy(lazyInternalsBundle((b) => b.BrokersPage));
const BrokerDetailPage = React.lazy(lazyInternalsBundle((b) => b.BrokerDetailPage));
const SlfInternalPage = React.lazy(lazyInternalsBundle((b) => b.SlfInternalPage));
const BrokerClientsPage = React.lazy(lazyInternalsBundle((b) => b.BrokerClientsPage));
const ClientDetailPage = React.lazy(lazyInternalsBundle((b) => b.ClientDetailPage));
const AllEmailsPage = React.lazy(lazyInternalsBundle((b) => b.AllEmailsPage));
const EmailPage = React.lazy(lazyInternalsBundle((b) => b.EmailPage));

// Onboard
const lazyOnboardBundle = lazyBundler(() => import("./Onboard.Bundle"));

const WelcomePage = React.lazy(lazyOnboardBundle((b) => b.WelcomePage));
const Hub = React.lazy(lazyOnboardBundle((b) => b.Hub));
const GiveFeedback = React.lazy(lazyOnboardBundle((b) => b.GiveFeedback));
const EnrollmentResourcesListingPage = React.lazy(
  lazyOnboardBundle((b) => b.EnrollmentResourcesListingPage),
);
const TaskDetailPage = React.lazy(lazyOnboardBundle((b) => b.TaskDetailPage));
const CensusDetailPage = React.lazy(lazyOnboardBundle((b) => b.CensusDetailPage));
const SignedPage = React.lazy(lazyOnboardBundle((b) => b.SignedPage));
const FormPage = React.lazy(lazyOnboardBundle((b) => b.FormPage));
const EIFStepPage = React.lazy(lazyOnboardBundle((b) => b.EIFStepPage));
const EIFSummaryPage = React.lazy(lazyOnboardBundle((b) => b.EIFSummaryPage));
const EIFSubStepPolicyPage = React.lazy(lazyOnboardBundle((b) => b.EIFSubStepPolicyPage));
const EIFPushToQPSPage = React.lazy(lazyOnboardBundle((b) => b.EIFPushToQPSPage));
const OutsideSignerPage = React.lazy(lazyOnboardBundle((b) => b.OutsideSignerPage));
const OutsideSignerEIFSummaryPage = React.lazy(
  lazyOnboardBundle((b) => b.OutsideSignerEIFSummaryPage),
);
const OutsideSignerPageSkeleton = React.lazy(lazyOnboardBundle((b) => b.OutsideSignerPageSkeleton));
const CensusSource = React.lazy(lazyOnboardBundle((b) => b.CensusSource));
const CensusUpload = React.lazy(lazyOnboardBundle((b) => b.CensusUpload));
const CensusValidateSubmit = React.lazy(lazyOnboardBundle((b) => b.CensusValidateSubmit));
const CensusValidationReportTab = React.lazy(lazyOnboardBundle((b) => b.CensusValidationReportTab));

// Benefits Explorer
const lazyBenExBundle = lazyBundler(() => import("./BenEx.Bundle"));

const ExplorerPageConfigPage = React.lazy(lazyBenExBundle((b) => b.ExplorerPageConfigPage));
const PersonalBenefitsPlanPage = React.lazy(lazyBenExBundle((b) => b.PersonalBenefitsPlanPage));
const GiveFeedbackExternal = React.lazy(lazyBenExBundle((b) => b.GiveFeedbackExternal));
const ExplorerPage = React.lazy(lazyBenExBundle((b) => b.ExplorerPage));
const ExplorerNavBarIndex = React.lazy(lazyBenExBundle((b) => b.ExplorerNavBarIndex));

export const MainLayout = () => {
  return (
    <AuthRedirectHandler>
      <>
        <AnalyticsPage />
        <WebSocketApp />
        <EmailSentNotification />
        <QPSPushProgressOverlay />
        <Outlet />
      </>
    </AuthRedirectHandler>
  );
};

type DefaultLayoutProps = {
  Page: React.ComponentType;
  NavBar?: React.ComponentType;
  Footer: React.ComponentType | null;
  fallback?: React.ReactNode;
  supportFooter?: boolean;
};

const DefaultLayout = ({
  Page,
  NavBar,
  Footer,
  fallback = <Loading />,
  supportFooter = false,
}: DefaultLayoutProps) => {
  useBenExCustomBranding();

  return (
    <>
      <a className={clsx("body2", styles.skipToMain)} href="#main-content">
        Skip to Main Content
      </a>

      {NavBar ? <NavBar /> : <NavBarPage />}

      <Sentry.ErrorBoundary
        fallback={(props) => (
          <main>
            <BoundaryError {...props} />
          </main>
        )}
      >
        <main className={styles.content} id="main-content">
          <Suspense fallback={fallback}>
            <Page />
          </Suspense>
        </main>
      </Sentry.ErrorBoundary>

      <footer>
        {supportFooter && <SupportFooter />}
        {Footer && <Footer />}
      </footer>
    </>
  );
};

export const appRoutes = (): RouteObject[] => [
  {
    path: RouteData.login.getPathTemplate(),
    element: <DefaultLayout Page={LoginPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.homeInternal.getPathTemplate(),
    element: <DefaultLayout Page={ClientsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.forgot.getPathTemplate(),
    element: <DefaultLayout Page={ForgotPasswordPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.activate.getPathTemplate(),
    element: <DefaultLayout Page={ActivatePage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.reactivate.getPathTemplate(),
    element: <DefaultLayout Page={ReActivatePage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.reset.getPathTemplate(),
    element: <DefaultLayout Page={ResetPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.termsOfUse.getPathTemplate(),
    element: <DefaultLayout Page={TermsOfUse} Footer={LegalFooter} />,
  },
  // #region Misc
  {
    path: RouteData.notFound.getPathTemplate(),
    element: <DefaultLayout Page={NotFoundPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.maintenance.getPathTemplate(),
    element: <DefaultLayout Page={MaintenancePage} Footer={LegalFooter} />,
  },
  // #endregion Misc

  // #region Internal Users
  {
    path: RouteData.homeInternal.getPathTemplate(),
    element: <DefaultLayout Page={ClientsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.homeBenAdmin.getPathTemplate(),
    element: <DefaultLayout Page={BenAdminClientsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.homeBroker.getPathTemplate(),
    element: <DefaultLayout Page={BrokerClientsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.clientDetail.getPathTemplate(),
    element: <DefaultLayout Page={ClientDetailPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.usersInternal.getPathTemplate(),
    element: <DefaultLayout Page={SlfInternalPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.brokers.getPathTemplate(),
    element: <DefaultLayout Page={BrokersPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.brokerDetail.getPathTemplate(),
    element: <DefaultLayout Page={BrokerDetailPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.allEmails.getPathTemplate(),
    element: <DefaultLayout Page={AllEmailsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.benAdmins.getPathTemplate(),
    element: <DefaultLayout Page={BenAdminsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.benAdminDetail.getPathTemplate(),
    element: <DefaultLayout Page={BenAdminDetailPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.emails.getPathTemplate(),
    element: <DefaultLayout Page={AllEmailsPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.email.getPathTemplate(),
    element: <DefaultLayout Page={EmailPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.emailClient.getPathTemplate(),
    element: <DefaultLayout Page={EmailPage} Footer={LegalFooter} />,
  },
  // #endregion Internal Users

  // #region Onboard
  {
    path: RouteData.welcome.getPathTemplate(),
    element: <DefaultLayout Page={WelcomePage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.homeHub.getPathTemplate(),
    element: (
      <DefaultLayout
        Page={() => (
          <>
            <Hub />
            <GiveFeedback />
          </>
        )}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.resourcesListing.getPathTemplate(),
    element: <DefaultLayout Page={EnrollmentResourcesListingPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.clientTaskDetail.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<ChildPageSkeleton />}
        Page={TaskDetailPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.policyTaskDetail.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<ChildPageSkeleton />}
        Page={TaskDetailPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.censusStepDetail.getPathTemplate(),
    element: <DefaultLayout Page={CensusDetailPage} Footer={LegalFooter} />,
    children: [
      {
        id: "upload-files",
        path: "upload-files",
        element: <CensusUpload />,
      },
      {
        id: "indicate-source",
        path: "indicate-source",
        element: <CensusSource />,
      },
      {
        id: "validate-submit",
        path: "validate-submit",
        element: <CensusValidateSubmit />,
      },
    ],
  },
  {
    path: RouteData.censusValidateReportTab.getPathTemplate(),
    element: <DefaultLayout Page={CensusValidationReportTab} Footer={LegalFooter} />,
  },
  {
    path: RouteData.onboardingFormsSigned.getPathTemplate(),
    element: <DefaultLayout Page={SignedPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.onboardingFormsViewer.getPathTemplate(),
    element: <DefaultLayout Page={FormPage} Footer={null} />,
  },
  {
    path: RouteData.eifStepDetail.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<EIFStepPageLoading />}
        Page={EIFStepPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.eifSubStepPolicyDetail.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<EIFStepPageLoading />}
        Page={EIFSubStepPolicyPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.eifSubStepDetail.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<EIFStepPageLoading />}
        Page={EIFStepPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.eifClassBuilderEdit.getPathTemplate(),
    element: (
      <DefaultLayout fallback={<EIFStepPageLoading />} Page={EIFStepPage} Footer={LegalFooter} />
    ),
  },
  {
    path: RouteData.eifClassBuilderCreator.getPathTemplate(),
    element: (
      <DefaultLayout fallback={<EIFStepPageLoading />} Page={EIFStepPage} Footer={LegalFooter} />
    ),
  },
  {
    path: RouteData.eifSummary.getPathTemplate(),
    element: (
      <DefaultLayout fallback={<EIFSummarySkeleton />} Page={EIFSummaryPage} Footer={LegalFooter} />
    ),
  },
  {
    path: RouteData.eifPushToQPS.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<EIFSummarySkeleton />}
        Page={EIFPushToQPSPage}
        Footer={LegalFooter}
      />
    ),
  },
  {
    path: RouteData.outsideSigner.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<OutsideSignerPageSkeleton />}
        Page={OutsideSignerPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.outsideSignerEifSummary.getPathTemplate(),
    element: (
      <DefaultLayout
        fallback={<EIFSummarySkeleton />}
        Page={OutsideSignerEIFSummaryPage}
        Footer={LegalFooter}
        supportFooter={true}
      />
    ),
  },
  {
    path: RouteData.createBenefitsExplorerSite.getPathTemplate(),
    element: <DefaultLayout Page={ExplorerPageConfigPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.editBenefitsExplorerSite.getPathTemplate(),
    element: <DefaultLayout Page={ExplorerPageConfigPage} Footer={LegalFooter} />,
  },
  {
    path: RouteData.renewBenefitsExplorerSite.getPathTemplate(),
    element: (
      <DefaultLayout
        Page={() => (
          <>
            <ExplorerPageConfigPage isRenewal={true} />
          </>
        )}
        Footer={LegalFooter}
      />
    ),
  },
  // #endregion Onboard

  // #region Benefits Explorer
  {
    path: RouteData.benefitsExplorerIndexInternal.getPathTemplate(),
    element: (
      <DefaultLayout
        Page={() => (
          <>
            <ExplorerPageIndex />
            <GiveFeedbackExternal />
          </>
        )}
        NavBar={ExplorerNavBarIndex}
        Footer={LegalFooter}
      />
    ),
  },
  {
    path: RouteData.benefitsExplorerInternal.getPathTemplate(),
    element: (
      <DefaultLayout
        Page={() => (
          <>
            <ExplorerPage />
            <GiveFeedbackExternal />
          </>
        )}
        Footer={LegalFooter}
      />
    ),
  },
  {
    path: RouteData.personalBenefitsPlanInternal.getPathTemplate(),
    element: (
      <DefaultLayout
        Page={() => (
          <>
            <PersonalBenefitsPlanPage />
            <GiveFeedbackExternal />
          </>
        )}
        Footer={LegalFooter}
      />
    ),
  },
  // #endregion

  // #region Code Splitting lab
  {
    path: "/lab/home",
    element: (
      <DefaultLayout Page={React.lazy(() => import("./pages/Lab/LabHome"))} Footer={LegalFooter} />
    ),
  },
  {
    path: "/lab/about",
    element: (
      <DefaultLayout Page={React.lazy(() => import("./pages/Lab/LabAbout"))} Footer={LegalFooter} />
    ),
  },
  // #endregion Code Splitting lab

  { path: `${RouteData.login.getPathTemplate()}/callback` },
  {
    path: "*",
    element: <DefaultLayout Page={NotFoundPage} Footer={LegalFooter} />,
  },
];

const getRouterRoutes = (): RouteObject[] => [
  {
    path: "/",
    element: MainLayout(),
    children: [
      { index: true, element: <IndexPage /> },

      ...appRoutes().map((onboardRoute) => ({
        id: onboardRoute.id,
        path: onboardRoute.path,
        element: onboardRoute.element,
        children: onboardRoute.children,
      })),
    ],
  },
];

// #region Your Benefits explorer routes
const appRoutesYourBenefitsExplorer = (): RouteObject[] => [
  {
    path: RouteData.benefitsExplorerIndex.getPathTemplate(),
    element: (
      <DefaultLayout
        Page={() => (
          <>
            <ExplorerPageIndex />
            <GiveFeedbackExternal />
          </>
        )}
        NavBar={ExplorerNavBarIndex}
        Footer={YourBenefitsExplorerLegalFooter}
      />
    ),
  },
  {
    path: RouteData.benefitsExplorer.getPathTemplate(),
    element: (
      <DefaultLayout
        Page={() => (
          <>
            <ExplorerPage />
            <GiveFeedbackExternal />
          </>
        )}
        Footer={LegalFooter}
      />
    ),
  },
  {
    path: RouteData.personalBenefitsPlan.getPathTemplate(),
    element: (
      <DefaultLayout
        Page={() => (
          <>
            <PersonalBenefitsPlanPage />
            <GiveFeedbackExternal />
          </>
        )}
        Footer={LegalFooter}
      />
    ),
  },
];

const getRouterRoutesYourBenefitsExplorer = (): RouteObject[] => [
  {
    path: "/",
    element: MainLayout(),
    children: [
      ...appRoutesYourBenefitsExplorer().map((onboardRoute) => ({
        id: onboardRoute.id,
        path: onboardRoute.path,
        element: onboardRoute.element,
        children: onboardRoute.children,
      })),
    ],
  },
];

// #endregion Your Benefits explorer routes

export const AppRouter = () => {
  const onboardRoutes = getRouterRoutes();
  const benefitsExplorerRoutes = getRouterRoutesYourBenefitsExplorer();

  const routes = isYourBenefitsExplorer() ? benefitsExplorerRoutes : onboardRoutes;

  const router = createBrowserRouter(routes);
  return <RouterProvider router={router} />;
};
