import { GenericErrorCopy2 } from "client/src/components/Error/ErrorMessage";
import { ResponseError } from "client/src/hooks/query";
import { useFormik } from "formik";

import set from "lodash/set";
import { ValidationError } from "yup";

import type { FormikConfig, FormikErrors, FormikHelpers, FormikProps } from "formik";
import type { ValuesForValidationSchema } from "shared/types/Helper";
import type { ObjectSchema, AnyObject, InferType } from "yup";

export type FormikTypeFromValidator<S extends ObjectSchema<AnyObject>> = FormikProps<
  ValuesForValidationSchema<S>
>;

export const useSlobFormik = <ValidationType extends ObjectSchema<AnyObject>>(
  args: Omit<
    FormikConfig<ValuesForValidationSchema<ValidationType>>,
    "validationSchema" | "onSubmit"
  > & {
    validationSchema: ValidationType;
    validationContext?: AnyObject;
    onSubmit: (
      values: InferType<ValidationType>,
      formikHelpers: FormikHelpers<ValuesForValidationSchema<ValidationType>>,
    ) => void | Promise<boolean | void>;
  },
) => {
  const { validationSchema, validationContext, ...rest } = args;

  const formik = useFormik({
    validateOnBlur: true,
    ...rest,
    validate: (values) => {
      return getFormikErrors(values, validationSchema, validationContext);
    },
    onSubmit: async (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      formikHelpers.setStatus(null);
      try {
        // This should always succeed, just doing this to get the right schema
        const validatedVals = args.validationSchema.validateSync(values, {
          context: validationContext,
        });
        await args.onSubmit(validatedVals, formikHelpers);
      } catch (error) {
        const msg = ResponseError.getUserFacingErrorMessage(error, GenericErrorCopy2);

        if (ResponseError.isResponseError(error)) {
          const hasValidationError = (obj: unknown): obj is { validationError: unknown } => {
            const isIt = obj != null && typeof obj === "object" && "validationError" in obj;
            return isIt;
          };

          if (hasValidationError(error.data)) {
            if (ValidationError.isError(error.data.validationError)) {
              const validationError: ValidationError = error.data.validationError;
              const innerValidationErrors =
                validationError.inner.length > 0 ? validationError.inner : [validationError];

              let fieldErrorSet = false;

              for (const innerValidationError of innerValidationErrors) {
                if (innerValidationError.path) {
                  // If the validation error is coming from the `useValidation` fn in the server,
                  // properties from the payload will be prefixed with "body."
                  const path = innerValidationError.path.replaceAll(/^body\./g, "");
                  formikHelpers.setFieldError(path, innerValidationError.message);
                  fieldErrorSet = true;
                }
              }

              if (!fieldErrorSet) {
                formikHelpers.setStatus(msg);
              }
            } else {
              formikHelpers.setStatus(msg);
            }
          } else {
            formikHelpers.setStatus(msg);
          }
        } else {
          console.error(error);
          formikHelpers.setStatus(msg);
        }
      } finally {
        // If onSubmit is async, then Formik will automatically set isSubmitting to false
        formikHelpers.setSubmitting(false);
      }
    },
  });

  return formik;
};

export function getFormikErrors<ValidationType extends ObjectSchema<AnyObject>>(
  values: ValuesForValidationSchema<ValidationType>,
  validationSchema: ValidationType,
  validationContext?: AnyObject,
): FormikErrors<ValuesForValidationSchema<ValidationType>> {
  try {
    validationSchema.validateSync(values, {
      abortEarly: false,
      context: validationContext,
    });
  } catch (error) {
    if (error instanceof ValidationError) {
      const errors = error.inner.reduce<FormikErrors<ValuesForValidationSchema<ValidationType>>>(
        (errors, currentError) => {
          if (currentError.path) errors = set(errors, currentError.path, currentError.message);
          return errors;
        },
        {},
      );
      return errors;
    } else {
      throw error;
    }
  }
  return {};
}
