import { Button } from "client/src/components/Button/Button";
import { NavBar } from "client/src/components/NavBar/NavBar";
import { RouteData } from "shared/config/routeData";
import type { logOutType } from "client/src/pages/Navigation/NavBarPage";

type BasicPageProps = {
  logOut?: logOutType;
};

export const BasicNav = ({ logOut }: BasicPageProps) => {
  const handleLogOut = async () => {
    await logOut?.({
      data: {},
    });
  };
  return (
    <NavBar
      homeLink={RouteData.index.getPathTemplate()}
      rightLinks={
        <>
          {logOut && (
            <Button size="middle" type="text-only-link" onClick={handleLogOut}>
              Sign Out
            </Button>
          )}
        </>
      }
    />
  );
};
