import { Portal } from "@reach/portal";
import { QPSPushProgressIndicator } from "client/src/domain/EIF/QPSPushProgressIndicator/QPSPushProgressIndicator";
import { useWebSocketQPSPushStatusEvent } from "client/src/hooks/qps";
import clsx from "clsx";
import { createContext, use, useState } from "react";

import * as styles from "./QPSPushProgressOverlay.module.less";

import type { PropsWithChildren } from "react";

import type { Client, ClientId } from "shared/types/Client";

export type ProgressOverlayStatus = "loading" | "success-with-warnings" | "success" | "error";

type ContextValue = {
  cases: Array<{
    client: Client;
    status: ProgressOverlayStatus;
  }>;
  addClient: (client: Client) => void;
  updateStatus: (clientId: ClientId, status: ProgressOverlayStatus) => void;
  removeClient: (client: Client) => void;
  drawerIsOpen: boolean;
  setDrawerIsOpen: (isOpen: boolean) => void;
};

export const ProgressOverlayContext = createContext<ContextValue | null>(null);

export const useProgressOverlayContext = () => {
  const context = use(ProgressOverlayContext);

  if (context == null) {
    throw new Error("useProgressOverlayContext must be used within a ProgressOverlayProvider");
  }

  return context;
};

export function ProgressOverlayProvider({ children }: PropsWithChildren) {
  const [cases, setCases] = useState<ContextValue["cases"]>([]);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const addClient = (client: Client) => {
    document.body.setAttribute("data-qps-push-progress-overlay", "true");

    document.startViewTransition(() => {
      setCases((prevCases) => {
        let shouldPush = true;
        const nextCases = [];
        for (const clientCase of prevCases) {
          if (clientCase.client.id === client.id) {
            nextCases.push({ client, status: "loading" as const });
            shouldPush = false;
          } else {
            nextCases.push(clientCase);
          }
        }

        if (shouldPush) {
          nextCases.push({ client, status: "loading" as const });
        }

        return nextCases;
      });
    });
  };

  const updateStatus = (clientId: ClientId, status: ProgressOverlayStatus) => {
    setCases((prevCases) =>
      prevCases.map((clientCase) =>
        clientCase.client.id === clientId ? { ...clientCase, status } : clientCase,
      ),
    );
  };

  const removeClient = (client: Client) => {
    setCases((prevCases) => {
      const nextCases = prevCases.filter((clientCase) => clientCase.client.id !== client.id);
      if (nextCases.length === 0) {
        document.body.removeAttribute("data-qps-push-progress-overlay");
      }
      return nextCases;
    });
  };

  const value: ContextValue = {
    cases,
    addClient,
    updateStatus,
    removeClient,
    drawerIsOpen,
    setDrawerIsOpen,
  };

  return <ProgressOverlayContext value={value}>{children}</ProgressOverlayContext>;
}

export function QPSPushProgressOverlay() {
  useWebSocketQPSPushStatusEvent();

  const qpsProgressContext = useProgressOverlayContext();

  if (qpsProgressContext.cases.length === 0) {
    return null;
  }

  return (
    <Portal type="qps-push-progress-popover-portal">
      <div
        className={clsx(
          styles.qpsPushProgressOverlay,
          qpsProgressContext.drawerIsOpen && styles.drawerIsOpen,
        )}
      >
        <QPSPushProgressIndicator
          cases={qpsProgressContext.cases}
          onRemove={qpsProgressContext.removeClient}
        />
      </div>
    </Portal>
  );
}
