import { keepPreviousData, useQueryClient, useSuspenseQueries } from "@tanstack/react-query";
import { invalidateGetExplorerPages } from "client/src/hooks/ExplorerPage/useExplorerPages";
import { jsonAdditionalCompensation } from "client/src/hooks/additionalCompensation";
import { jsonBenAdminPlatformToBenAdminPlatform } from "client/src/hooks/benAdminPlatforms";
import { jsonBillToBill } from "client/src/hooks/bill";
import { invalidateDEIFChanges, jsonChangeToChange } from "client/src/hooks/changeLogs";
import { jsonContactToContact } from "client/src/hooks/contact";
import { getDocumentsParams, jsonCensusDocumentToCensusDocument } from "client/src/hooks/document";
import { invalidateGetEmailsQuery } from "client/src/hooks/email";
import { jsonClassToClass } from "client/src/hooks/employeeClass";
import { jsonLocationToLocation } from "client/src/hooks/location";
import { jsonMonthlyClaimsReportMailingLocationToMonthlyClaimsReportMailingLocation } from "client/src/hooks/monthlyClaimsReportMailingLocation";
import { jsonOnboardingFormToOnboardingForm } from "client/src/hooks/onboardingForms";
import { jsonPlanToPlan } from "client/src/hooks/plans";
import {
  compareQueryId,
  compareQueryKey,
  errorToMessage,
  getQueryArgs,
  ResponseError,
  useSlobMutation,
  useSlobQuery,
} from "client/src/hooks/query";
import { getTableQueryArguments } from "client/src/hooks/slobTable";
import { jsonSubsidaryToSubsidiary } from "client/src/hooks/subsidiary";
import { jsonTogglesToToggles } from "client/src/hooks/useFeatureToggles";
import {
  invalidateGetBenAdmins,
  invalidateGetBrokers,
  jsonBenAdminToBenAdmin,
  jsonBrokerToBroker,
  jsonSlfConsultantToUser,
  jsonUserToDomainUser,
} from "client/src/hooks/user";
import { useCallback } from "react";
import type { AdditionalCompensation, ClientError, ClientErrorType } from "@prisma/client";
import type { JsonToTypeMapper, SlobQueryType } from "client/src/hooks/query";
import type { TableQueryParams } from "client/src/hooks/slobTable";
import type { PolicyTask, Task } from "shared/data/Tasks";
import type { APIJob } from "shared/types/APIJob";
import type { APIJobOutput } from "shared/types/APIJobOutput";
import type { BenAdminPlatform } from "shared/types/BenAdminPlatform";
import type { Bill } from "shared/types/Bill";
import type { ChangeWithDetails } from "shared/types/Change";
import type {
  Client,
  ClientBaseInfoResponse,
  ClientId,
  ClientSetup,
  ClientInput,
  ClientSetupSteps,
  ClientTableFilters,
  ClientUserTableFilters,
  Policy,
  ClientFromQPS,
} from "shared/types/Client";
import type { ClientUser } from "shared/types/ClientUser";
import type { Contact } from "shared/types/Contact";
import type { CensusDocument } from "shared/types/Document";
import type {
  ClientSetupPlanConfigurationInput,
  ClientSetupPlanConfigurationOutput,
  EIFSubStepId,
} from "shared/types/EIF";
import type { EmployeeClass } from "shared/types/EmployeeClass";
import type { Location } from "shared/types/Location";
import type { OutsideSigner } from "shared/types/OutsideSigner";
import type { Plan } from "shared/types/Plan";
import type { Subsidiary } from "shared/types/Subsidiary";
import type { ClientFeatureToggles } from "shared/types/Toggles";
import type { UserId } from "shared/types/User";

const jsonClientErrorToClientError: JsonToTypeMapper<ClientError> = (clientError) => ({
  ...clientError,
  createdAt: new Date(clientError.createdAt),
  updatedAt: new Date(clientError.updatedAt),
  deletedAt: clientError.deletedAt ? new Date(clientError.deletedAt) : null,
});

const jsonOutsideSignerToOutsideSigner: JsonToTypeMapper<OutsideSigner> = (outsideSigner) => {
  return {
    ...outsideSigner,
    createdAt: new Date(outsideSigner.createdAt),
    updatedAt: new Date(outsideSigner.updatedAt),
    deletedAt: outsideSigner.deletedAt ? new Date(outsideSigner.deletedAt) : null,
    tokenIssuedAt: outsideSigner.tokenIssuedAt ? new Date(outsideSigner.tokenIssuedAt) : null,
  };
};

export const jsonAPIJobToAPIJob: JsonToTypeMapper<APIJob> = (apiJob) => {
  return {
    ...apiJob,
    createdAt: new Date(apiJob.createdAt),
    updatedAt: new Date(apiJob.updatedAt),
    deletedAt: apiJob.deletedAt ? new Date(apiJob.deletedAt) : null,
    apiJobOutputs: apiJob.apiJobOutputs.map(jsonAPIJobOutputToAPIJobOutput),
  };
};

const jsonAPIJobOutputToAPIJobOutput: JsonToTypeMapper<APIJobOutput> = (apiJobOutput) => {
  return {
    ...apiJobOutput,
    createdAt: new Date(apiJobOutput.createdAt),
    updatedAt: new Date(apiJobOutput.updatedAt),
    deletedAt: apiJobOutput.deletedAt ? new Date(apiJobOutput.deletedAt) : null,
  };
};

export const jsonClientToClient: JsonToTypeMapper<Client> = (client) => {
  return {
    ...client,
    plans: client.plans.map(jsonPlanToPlan),
    bills: client.bills.map(jsonBillToBill),
    deletedBills: client.deletedBills.map(jsonBillToBill),
    subsidiaries: client.subsidiaries.map(jsonSubsidaryToSubsidiary),
    contacts: client.contacts.map(jsonContactToContact),
    clientErrors:
      client.clientErrors?.map((clientError) => jsonClientErrorToClientError(clientError)) || null,
    slfImplementationConsultant: client.slfImplementationConsultant
      ? jsonSlfConsultantToUser(client.slfImplementationConsultant)
      : null,
    firstPolicyEffective: client.firstPolicyEffective
      ? new Date(client.firstPolicyEffective)
      : null,
    enrollmentDateStart: client.enrollmentDateStart ? new Date(client.enrollmentDateStart) : null,
    enrollmentDateEnd: client.enrollmentDateEnd ? new Date(client.enrollmentDateEnd) : null,
    enrollmentNeedsUpdatedAt: client.enrollmentNeedsUpdatedAt
      ? new Date(client.enrollmentNeedsUpdatedAt)
      : null,
    enrollmentEventUpdatedAt: client.enrollmentEventUpdatedAt
      ? new Date(client.enrollmentEventUpdatedAt)
      : null,
    setYourGoalsUpdatedAt: client.setYourGoalsUpdatedAt
      ? new Date(client.setYourGoalsUpdatedAt)
      : null,
    kickoffCallUpdatedAt: client.kickoffCallUpdatedAt
      ? new Date(client.kickoffCallUpdatedAt)
      : null,
    installCallUpdatedAt: client.installCallUpdatedAt
      ? new Date(client.installCallUpdatedAt)
      : null,
    sLConnectCredentialSentDate: client.sLConnectCredentialSentDate
      ? new Date(client.sLConnectCredentialSentDate)
      : null,
    riskAcceptanceEmailSentDate: client.riskAcceptanceEmailSentDate
      ? new Date(client.riskAcceptanceEmailSentDate)
      : null,
    genericDentalIdCardEmailSentDate: client.genericDentalIdCardEmailSentDate
      ? new Date(client.genericDentalIdCardEmailSentDate)
      : null,
    personalizedDentalIdCardEmailSentDate: client.personalizedDentalIdCardEmailSentDate
      ? new Date(client.personalizedDentalIdCardEmailSentDate)
      : null,
    users: client.users ? client.users.map(jsonClientUserToClientUser) : null,
    tasks: client.tasks ? client.tasks.map(jsonClientTaskToClientTask) : null,
    policies: client.policies.map(jsonClientPoliciesToClientPolicies),
    jiraUpdatedAt: client.jiraUpdatedAt ? new Date(client.jiraUpdatedAt) : null,
    liveReleaseStatusAt: client.liveReleaseStatusAt ? new Date(client.liveReleaseStatusAt) : null,
    createdAt: new Date(client.createdAt),
    updatedAt: new Date(client.updatedAt),
    deletedAt: null,
    webAdminContact: client.webAdminContact ? jsonContactToContact(client.webAdminContact) : null,
    additionalWebAdminContacts: client.additionalWebAdminContacts
      ? client.additionalWebAdminContacts.map(jsonContactToContact)
      : [],

    authorizedSignerUser: client.authorizedSignerUser
      ? jsonUserToDomainUser(client.authorizedSignerUser)
      : client.authorizedSignerUser,

    outsideSigner: client.outsideSigner
      ? jsonOutsideSignerToOutsideSigner(client.outsideSigner)
      : client.outsideSigner,

    eifSignedAt: client.eifSignedAt ? new Date(client.eifSignedAt) : null,
    eifSignedByUser: client.eifSignedByUser,
    eifOutsideSignedByUser: client.eifOutsideSignedByUser
      ? jsonOutsideSignerToOutsideSigner(client.eifOutsideSignedByUser)
      : null,
    completedSetupAt: client.completedSetupAt ? new Date(client.completedSetupAt) : null,
    eifBAReviewAndSubmitEmailSentDate: client.eifBAReviewAndSubmitEmailSentDate
      ? new Date(client.eifBAReviewAndSubmitEmailSentDate)
      : null,

    deifChangesAcceptedAt: client.deifChangesAcceptedAt
      ? new Date(client.deifChangesAcceptedAt)
      : null,
    deifChangesDeclinedAt: client.deifChangesDeclinedAt
      ? new Date(client.deifChangesDeclinedAt)
      : null,
    deifChangesReadyForReviewAt: client.deifChangesReadyForReviewAt
      ? new Date(client.deifChangesReadyForReviewAt)
      : null,

    deifChangesOutsideAcceptedByUser: client.deifChangesOutsideAcceptedByUser
      ? jsonOutsideSignerToOutsideSigner(client.deifChangesOutsideAcceptedByUser)
      : null,
    deifChangesOutsideDeclinedByUser: client.deifChangesOutsideDeclinedByUser
      ? jsonOutsideSignerToOutsideSigner(client.deifChangesOutsideDeclinedByUser)
      : null,

    activelyAtWorkUpdatedAt: client.activelyAtWorkUpdatedAt
      ? new Date(client.activelyAtWorkUpdatedAt)
      : null,

    pushedToCompassAt: client.pushedToCompassAt ? new Date(client.pushedToCompassAt) : null,

    monthlyClaimsReportMailingLocations: client.monthlyClaimsReportMailingLocations
      ? client.monthlyClaimsReportMailingLocations.map(
          jsonMonthlyClaimsReportMailingLocationToMonthlyClaimsReportMailingLocation,
        )
      : null,

    apiJobs: client.apiJobs.map(jsonAPIJobToAPIJob),
  };
};

export const jsonClientPoliciesToClientPolicies: JsonToTypeMapper<Policy> = (policy) => {
  return {
    ...policy,
    policyEffective: policy.policyEffective ? new Date(policy.policyEffective) : null,
    enrollmentCensusDeadline: policy.enrollmentCensusDeadline
      ? new Date(policy.enrollmentCensusDeadline)
      : null,
    infoGatheringDeadline: policy.infoGatheringDeadline
      ? new Date(policy.infoGatheringDeadline)
      : null,
    riskAcceptanceEmailSentDate: policy.riskAcceptanceEmailSentDate
      ? new Date(policy.riskAcceptanceEmailSentDate)
      : null,
    genericDentalIdCardEmailSentDate: policy.genericDentalIdCardEmailSentDate
      ? new Date(policy.genericDentalIdCardEmailSentDate)
      : null,
    personalizedDentalIdCardEmailSentDate: policy.personalizedDentalIdCardEmailSentDate
      ? new Date(policy.personalizedDentalIdCardEmailSentDate)
      : null,
    liveStatusDate: policy.liveStatusDate ? new Date(policy.liveStatusDate) : null,
    policyTasks: policy.policyTasks
      ? policy.policyTasks.map(jsonClientPolicyTaskToClientPolicyTask)
      : null,
    benAdminPlatform: policy.benAdminPlatform
      ? jsonBenAdminPlatformToBenAdminPlatform(policy.benAdminPlatform)
      : null,
    benAdminPlatformUpdatedAt: policy.benAdminPlatformUpdatedAt
      ? new Date(policy.benAdminPlatformUpdatedAt)
      : null,
    dataFeedsBenAdminContact: policy.dataFeedsBenAdminContact
      ? jsonContactToContact(policy.dataFeedsBenAdminContact)
      : null,
    dataFeedsImplementorContact: policy.dataFeedsImplementorContact
      ? jsonContactToContact(policy.dataFeedsImplementorContact)
      : null,
    dataFeedsProductionSupportContact: policy.dataFeedsProductionSupportContact
      ? jsonContactToContact(policy.dataFeedsProductionSupportContact)
      : null,
    tpaContact: policy.tpaContact ? jsonContactToContact(policy.tpaContact) : null,
    dataFeedsUpdatedAt: policy.dataFeedsUpdatedAt ? new Date(policy.dataFeedsUpdatedAt) : null,
    qpsEvents: policy.qpsEvents.map((qpsEvent) => ({
      ...qpsEvent,
      policyEffective: new Date(qpsEvent.policyEffective),
      timestamp: new Date(qpsEvent.timestamp),
      createdAt: new Date(qpsEvent.createdAt),
    })),
    jiraUpdatedAt: new Date(policy.jiraUpdatedAt),
    jiraCreatedAt: new Date(policy.jiraCreatedAt),
    createdAt: new Date(policy.createdAt),
    updatedAt: new Date(policy.updatedAt),
    deletedAt: null,
  };
};

const jsonClientUserToClientUser: JsonToTypeMapper<ClientUser> = (clientUser) => {
  return {
    ...clientUser,
    createdAt: new Date(clientUser.createdAt),
    updatedAt: new Date(clientUser.updatedAt),
    deletedAt: clientUser.deletedAt ? new Date(clientUser.deletedAt) : null,
    welcomeFlowCompletedAt: clientUser.welcomeFlowCompletedAt
      ? new Date(clientUser.welcomeFlowCompletedAt)
      : null,
    user: clientUser.user ? jsonUserToDomainUser(clientUser.user) : undefined,
  };
};

const jsonClientSetupToClientSetup: JsonToTypeMapper<ClientSetup> = (clientSetup) => {
  return {
    ...clientSetup,
    broker: {
      ...clientSetup.broker,
      data: clientSetup.broker.data.map(jsonBrokerToBroker),
    },
    benAdmin: {
      ...clientSetup.benAdmin,
      data: clientSetup.benAdmin.data.map(jsonBenAdminToBenAdmin),
    },
    docusignEnvelop: {
      ...clientSetup.docusignEnvelop,
      data: jsonOnboardingFormToOnboardingForm(clientSetup.docusignEnvelop.data),
    },
    configCompanyInfo: {
      ...clientSetup.configCompanyInfo,
      data: clientSetup.configCompanyInfo.data.map(jsonPlanToPlan),
    },
  };
};

const jsonClientTaskToClientTask: JsonToTypeMapper<Task> = (task) => {
  return {
    ...task,
    dueDate: task.dueDate ? new Date(task.dueDate) : null,
  };
};

const jsonClientPolicyTaskToClientPolicyTask: JsonToTypeMapper<PolicyTask> = (task) => {
  return {
    ...task,
    dueDate: task.dueDate ? new Date(task.dueDate) : null,
  };
};

export const useGetClientByID = (
  clientId: ClientId | null | undefined,
  options: SlobQueryType<Client, ResponseError>["options"] = {},
) => {
  const { enabled, ...rest } = options;

  const query = useSlobQuery<Client>({
    method: "get",
    path: `/api/clients/${clientId}`,
    map: jsonClientToClient,
    options: {
      retryOnMount: false,
      enabled: enabled === undefined ? !!clientId : enabled && !!clientId,
      ...rest,
    },
  });

  return query;
};

export const CLIENTS_LIST_PAGE_SIZE = 25;
export const useGetClients = (
  query: TableQueryParams<ClientTableFilters>,
  options: SlobQueryType<
    {
      data: Client[];
      meta: {
        count: number;
        clientWithErrorsCount: number;
        clientByErrorCount: Record<ClientErrorType, number>;
      };
    },
    ResponseError
  >["options"] = {},
) => {
  const params = getTableQueryArguments(query);
  return useSlobQuery<{
    data: Client[];
    meta: {
      count: number;
      clientWithErrorsCount: number;
      clientByErrorCount: Record<ClientErrorType, number>;
    };
  }>({
    method: "get",
    path: `/api/clients?${params.toString()}`,
    queryId: "get-clients",
    map: (response) => ({
      ...response,
      data: response.data.map(jsonClientToClient),
    }),
    options: { placeholderData: keepPreviousData, ...options },
  });
};

export const useGetUserClients = ({
  userId,
  ...query
}: TableQueryParams<ClientUserTableFilters> & Record<string, unknown>) => {
  const params = getTableQueryArguments(query);
  return useSlobQuery<{ data: Client[]; meta: { count: number } }>({
    method: "get",
    path: `/api/users/${userId}/clients?${params.toString()}`,
    map: (response) => ({ ...response, data: response.data.map(jsonClientToClient) }),
    options: { placeholderData: keepPreviousData, enabled: !!userId },
  });
};

export const useGetClientInfoFromTicketByTicketId = (ticketId: string) =>
  useSlobQuery<ClientBaseInfoResponse>({
    method: "get",
    path: `/api/clients/ticket/${ticketId}/info`,
    options: {
      enabled: !!ticketId,
      staleTime: 0,
    },
    map: (i) => i,
  });

export const useCreateClient = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<{ ticketId: string }, Client>({
    method: "post",
    path: `/api/clients/`,
    map: jsonClientToClient,
    options: {
      async onSuccess({ data: _client }) {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/clients`]),
        });
      },
    },
  });
};

export const useDeleteClient = () => {
  const queryClient = useQueryClient();

  return useSlobMutation({
    method: "delete",
    path: `/api/clients/:clientId`,
    options: {
      async onSuccess({ data: _client }) {
        await queryClient.invalidateQueries({
          predicate: compareQueryId("get-clients"),
        });
      },
    },
  });
};

export type UpdateClientQuery = Pick<
  ReturnType<typeof useUpdateClient>,
  "isPending" | "isError" | "error" | "mutateAsync"
>;

export type UpdateClientFunc = UpdateClientQuery["mutateAsync"];

export const useUpdateClient = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    Partial<ClientInput>,
    Client,
    `/api/clients/:clientId`,
    ResponseError,
    unknown,
    { prefill?: boolean }
  >({
    method: "put",
    path: `/api/clients/:clientId`,
    map: jsonClientToClient,
    options: {
      async onSuccess({ data: client }, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/bills`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/contacts`]),
          }),
          await invalidateGetBenAdmins(queryClient),
          invalidateDEIFChanges(queryClient, client.id),
        ]);
        queryClient.setQueryData(["get", `/api/clients/${clientId}`], client);
      },
    },
  });
};

export const useSyncClient = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<Partial<Client>, { status: "OK" }, `/api/clients/:clientId/sync`>({
    method: "put",
    path: `/api/clients/:clientId/sync`,
    map: (data) => data,
    options: {
      onSuccess: async function (_, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients`]),
          }),
        ]);
      },
      onError: async function () {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/clients`]),
        });
      },
    },
  });
};

export const usePutClientInDraftMode = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<{ isActive: boolean }, null, `/api/clients/:clientId/draft`>({
    method: "patch",
    path: `/api/clients/:clientId/draft`,
    map: (x) => x,
    options: {
      async onSuccess() {
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/clients`]),
        });
      },
    },
  });
};

export const useWelcomeFlow = (clientId: ClientId) => {
  return useSlobQuery<ClientUser>({
    method: "get",
    path: `/api/clients/${clientId}/welcome-flow`,
    map: jsonClientUserToClientUser,
    options: {
      // Welcome flow is only displayed once per user per client, ever.
      // So there's no point in garbage collecting this data
      gcTime: Infinity,
      staleTime: Infinity,
    },
  });
};

export const useCompleteWelcomeFlow = () => {
  const queryClient = useQueryClient();
  return useSlobMutation({
    method: "post",
    path: `/api/clients/:clientId/complete-welcome-flow`,
    options: {
      onSuccess({ data: clientUser }, { params: { clientId } }) {
        queryClient.setQueryData(["get", `/api/clients/${clientId}/welcome-flow`], clientUser);
      },
    },
  });
};

export const clientIsNotAccessibleError = (err: unknown) => {
  if (ResponseError.isResponseError(err)) {
    return err.status === 503 && errorToMessage(err) === "Client is not accessible at the moment";
  }
  return false;
};

export type SetupClientPlanConfigurationQuery = Pick<
  ReturnType<typeof useSetupClientPlanConfiguration>,
  "isPending" | "isError" | "error" | "mutateAsync"
>;

export const useSetupClientPlanConfiguration = (clientId: ClientId) => {
  const queryClient = useQueryClient();
  return useSlobMutation<ClientSetupPlanConfigurationInput, ClientSetupPlanConfigurationOutput>({
    method: "post",
    path: `/api/clients/${clientId}/plan-configuration`,
    options: {
      onSuccess: async function () {
        await Promise.all([
          queryClient.invalidateQueries({
            queryKey: ["get", `/api/clients/${clientId}/plans`],
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
        ]);
      },
    },
  });
};

export const useLinkToClient = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<{ baseClientId: string }, Client, `/api/clients/:clientId/base-client`>({
    method: "post",
    path: `/api/clients/:clientId/base-client`,
    map: jsonClientToClient,
    options: {
      async onSuccess({ data: client }, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients`]),
          }),
          invalidateGetBrokers(queryClient),
          invalidateGetBenAdmins(queryClient),
          invalidateGetExplorerPages(queryClient, clientId),
        ]);

        queryClient.setQueryData(["get", `/api/clients/${clientId}`], client);
      },
    },
  });
};

export const useClientSetup = (clientId: ClientId) =>
  useSlobQuery<ClientSetup>({
    method: "get",
    path: `/api/clients/${clientId}/setup`,
    map: jsonClientSetupToClientSetup,
  });

export const useLogClientSetupStepStatus = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    Partial<ClientSetupSteps>,
    boolean,
    `/api/clients/:clientId/setup-steps/:stepId`
  >({
    method: "post",
    path: `/api/clients/:clientId/setup-steps/:stepId`,
    options: {
      onSuccess: function ({ data }, { params: { clientId } }) {
        queryClient.setQueryData(["get", `/api/clients/${clientId}/setup-steps`], data);
      },
    },
  });
};

export function useSuspenseEIFQueries(clientId: ClientId) {
  const clientQuery = getQueryArgs<Client>({
    method: "get",
    path: `/api/clients/${clientId}`,
    map: jsonClientToClient,
  });
  const employeeClassesQuery = getQueryArgs<EmployeeClass[]>({
    method: "get",
    path: `/api/clients/${clientId}/employee-classes`,
    map: (ec) => ec.map(jsonClassToClass),
  });
  const clientPlans = getQueryArgs<Plan[]>({
    method: "get",
    path: `/api/clients/${clientId}/plans`,
    map: (plans) => plans.map(jsonPlanToPlan),
  });
  const locations = getQueryArgs<Location[]>({
    method: "get",
    path: `/api/clients/${clientId}/locations`,
    map: (locations) => locations.map(jsonLocationToLocation),
  });
  const bills = getQueryArgs<Bill[]>({
    method: "get",
    path: `/api/clients/${clientId}/bills`,
    map: (bills) => bills.map(jsonBillToBill),
    options: {
      select: useCallback((bills: Bill[]) => bills.filter((bill) => !bill.deletedAt), []),
    },
  });
  const deletedBills = getQueryArgs<Bill[]>({
    method: "get",
    path: `/api/clients/${clientId}/bills`,
    map: (bills) => bills.map(jsonBillToBill),
    options: {
      select: useCallback((bills: Bill[]) => bills.filter((bill) => bill.deletedAt), []),
    },
  });
  const contacts = getQueryArgs<Contact[]>({
    method: "get",
    path: `/api/clients/${clientId}/contacts`,
    map: (contacts) => contacts.map(jsonContactToContact),
  });
  const benAdminPlatforms = getQueryArgs<BenAdminPlatform[]>({
    method: "get",
    path: `/api/ben-admin-platforms`,
    map: (b) => b.map(jsonBenAdminPlatformToBenAdminPlatform),
  });
  const featureToggles = getQueryArgs<ClientFeatureToggles>({
    method: "get",
    path: `/api/feature-toggles?clientId=${clientId}`,
    map: jsonTogglesToToggles,
  });
  const changes = getQueryArgs<ChangeWithDetails[]>({
    method: "get",
    path: `/api/clients/${clientId}/change-log/deif-changes`,
    map: (c) => c.map(jsonChangeToChange),
  });

  const documents_qs = getDocumentsParams({
    categories: ["employee-classes"],
  });
  const documents = getQueryArgs<CensusDocument[]>({
    method: "get",
    path: `/api/clients/${clientId}/documents/meta?${documents_qs}`,
    map: (docs) => docs.map(jsonCensusDocumentToCensusDocument),
  });

  const documentCount_qs = getDocumentsParams({
    categories: ["employee-classes"],
  });
  const documentsCount = getQueryArgs<{ count: number }>({
    method: "get",
    path: `/api/clients/${clientId}/documents/count?${documentCount_qs}`,
    map: (d) => d,
  });

  const billingSummaryStatementTemplates_qs = getDocumentsParams({
    categories: ["billing-summary-statement-template"],
  });
  const billingSummaryStatementTemplates = getQueryArgs<CensusDocument[]>({
    method: "get",
    path: `/api/clients/${clientId}/documents/meta?${billingSummaryStatementTemplates_qs}`,
    map: (docs) => docs.map(jsonCensusDocumentToCensusDocument),
  });

  const additionalCompensations = getQueryArgs<AdditionalCompensation[]>({
    method: "get",
    path: `/api/clients/${clientId}/additional-compensations`,
    map: (ec) => ec.map(jsonAdditionalCompensation),
  });

  const subsidiaries = getQueryArgs<Subsidiary[]>({
    method: "get",
    path: `/api/clients/${clientId}/subsidiary`,
    queryId: "get-subsidiaries-by-client-id",
    map: (subsidiaries) => subsidiaries.map(jsonSubsidaryToSubsidiary),
  });

  const queries = useSuspenseQueries({
    queries: [
      clientQuery,
      employeeClassesQuery,
      clientPlans,
      locations,
      bills,
      deletedBills,
      contacts,
      benAdminPlatforms,
      featureToggles,
      changes,
      documents,
      documentsCount,
      billingSummaryStatementTemplates,
      additionalCompensations,
      subsidiaries,
    ],
  });

  return queries;
}

export function useSuspenseOutsideSignerQueries(clientId: ClientId) {
  const clientQuery = getQueryArgs<Client>({
    method: "get",
    path: `/api/clients/${clientId}`,
    map: jsonClientToClient,
  });
  const employeeClassesQuery = getQueryArgs<EmployeeClass[]>({
    method: "get",
    path: `/api/clients/${clientId}/employee-classes`,
    map: (ec) => ec.map(jsonClassToClass),
  });
  const clientPlans = getQueryArgs<Plan[]>({
    method: "get",
    path: `/api/clients/${clientId}/plans`,
    map: (plans) => plans.map(jsonPlanToPlan),
  });
  const bills = getQueryArgs<Bill[]>({
    method: "get",
    path: `/api/clients/${clientId}/bills`,
    map: (bills) => bills.map(jsonBillToBill),
    options: {
      select: useCallback((bills: Bill[]) => bills.filter((bill) => !bill.deletedAt), []),
    },
  });
  const deletedBills = getQueryArgs<Bill[]>({
    method: "get",
    path: `/api/clients/${clientId}/bills`,
    map: (bills) => bills.map(jsonBillToBill),
    options: {
      select: useCallback((bills: Bill[]) => bills.filter((bill) => bill.deletedAt), []),
    },
  });
  const contacts = getQueryArgs<Contact[]>({
    method: "get",
    path: `/api/clients/${clientId}/contacts`,
    map: (contacts) => contacts.map(jsonContactToContact),
  });
  const featureToggles = getQueryArgs<ClientFeatureToggles>({
    method: "get",
    path: `/api/feature-toggles?clientId=${clientId}`,
    map: jsonTogglesToToggles,
  });
  const changes = getQueryArgs<ChangeWithDetails[]>({
    method: "get",
    path: `/api/clients/${clientId}/change-log/deif-changes`,
    map: (c) => c.map(jsonChangeToChange),
  });
  const billingSummaryStatementTemplates_qs = getDocumentsParams({
    categories: ["billing-summary-statement-template"],
  });
  const billingSummaryStatementTemplates = getQueryArgs<CensusDocument[]>({
    method: "get",
    path: `/api/clients/${clientId}/documents/meta?${billingSummaryStatementTemplates_qs}`,
    map: (docs) => docs.map(jsonCensusDocumentToCensusDocument),
  });

  const subsidiaries = getQueryArgs<Subsidiary[]>({
    method: "get",
    path: `/api/clients/${clientId}/subsidiary`,
    queryId: "get-subsidiaries-by-client-id",
    map: (subsidiaries) => subsidiaries.map(jsonSubsidaryToSubsidiary),
  });

  const queries = useSuspenseQueries({
    queries: [
      clientQuery,
      employeeClassesQuery,
      clientPlans,
      bills,
      deletedBills,
      contacts,
      featureToggles,
      changes,
      billingSummaryStatementTemplates,
      subsidiaries,
    ],
  });

  return queries;
}

export const useClientFromQPS = (clientId: ClientId) =>
  useSlobQuery<ClientFromQPS>({
    method: "get",
    path: `/api/clients/${clientId}/import-from-qps`,
    map: (data) => data,
  });

export const useUpdateQPSPrefill = () => {
  return useSlobMutation<
    unknown,
    unknown,
    `/api/clients/:clientId/update-qps-prefill`,
    ResponseError,
    unknown
  >({
    method: "post",
    path: `/api/clients/:clientId/update-qps-prefill`,
  });
};

export const useSendWelcomeEmails = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<
    { userIds: UserId[] },
    { emailsSent: number; emailsFailed: number },
    "/api/clients/:clientId/activation-emails",
    ResponseError,
    unknown,
    never
  >({
    method: "post",
    path: `/api/clients/:clientId/activation-emails`,
    options: {
      async onSuccess() {
        await Promise.all([
          invalidateGetBrokers(queryClient),
          invalidateGetBenAdmins(queryClient),
          invalidateGetEmailsQuery(queryClient),
        ]);
      },
    },
  });
};

export const useUndoICEdits = () => {
  const queryClient = useQueryClient();

  return useSlobMutation<
    { eifSubStepId?: EIFSubStepId } | void,
    void,
    "/api/clients/:clientId/undo-ic-edits",
    ResponseError,
    unknown,
    never
  >({
    method: "post",
    path: `/api/clients/:clientId/undo-ic-edits`,
    options: {
      async onSuccess({ data: _client }, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
          }),
          invalidateDEIFChanges(queryClient, String(clientId)),
        ]);
      },
    },
  });
};
