import { useScrollToTopOnLocationChange } from "client/src/hooks/useScrollToTopOnLocationChange";
import {
  useAnalytics,
  useAnalyticsPage,
  useTrackExternalClick,
  useAnalyticsIdentify,
} from "client/src/utils/analytics";
import { useSetHelpCenterSuggestions, useZendeskWidget } from "client/src/utils/zendesk";
import { useLocation } from "react-router";

export const AnalyticsPage = () => {
  const location = useLocation();
  useSetHelpCenterSuggestions(location);
  useScrollToTopOnLocationChange();
  useZendeskWidget();

  useAnalytics();
  useAnalyticsPage();
  useTrackExternalClick();
  useAnalyticsIdentify();

  return null;
};
