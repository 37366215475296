import { Eyebrow } from "client/src/components/Typography/Typography";
import clsx from "clsx";
import { getDaysFromToday, isDateInThePast, isToday } from "shared/utils/date";
import { formatDateFullMonthWithYear } from "shared/utils/format";

import CalendarIcon from "../Icons/CalendarIcon.svg?react";

import * as styles from "./dueDate.module.less";

import type { Task } from "shared/data/Tasks";

type DueDateProps = {
  task: Task;
};

export function DueDate({ task }: DueDateProps) {
  const { dueDate } = task;

  if (!dueDate) return null;

  const daysFromToday = getDaysFromToday(dueDate);
  const todayDueDate = isToday(dueDate);
  const pastDueDate = isDateInThePast(dueDate) && !todayDueDate;
  const dueSoon = !pastDueDate && !todayDueDate && daysFromToday <= 5;
  const formattedDueDate = `Due by ${formatDateFullMonthWithYear(dueDate)}`;

  return (
    <div
      className={clsx(
        todayDueDate && styles.todayDueDate,
        pastDueDate && styles.dueDatePast,
        dueSoon && styles.dueDateSoon,
      )}
    >
      <CalendarIcon className={clsx(styles.icon, "mr-8")} />
      <Eyebrow>
        {todayDueDate
          ? "Due today"
          : pastDueDate
          ? `Past Due: ${formattedDueDate}`
          : dueSoon
          ? `Due Soon: ${formattedDueDate}`
          : formattedDueDate}
      </Eyebrow>
    </div>
  );
}
