import { getHasChangesByKeyList, rejectNullableValues } from "shared/utils/utils";
import type { LocationType } from "@prisma/client";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { ValueOf } from "shared/types/Helper";

export type LocationId = string;

export type Location = {
  id: LocationId;
  clientId: string;

  name: string | null;
  address1: string | null;
  address2: string | null;
  city: string | null;
  state: LocationStateCode | null;
  zipCode: string | null;
  locationType: LocationType | null;
  createdAt: Date;
  createdBy: string;
  updatedAt: Date;
  updatedBy: string;
  deletedAt: Date | null;
  deletedBy: string | null;
};

export type LocationInput = Pick<
  Location,
  "name" | "address1" | "address2" | "city" | "state" | "zipCode" | "locationType"
>;

export type LocationUpdateInput = Partial<
  Pick<
    Location,
    | "name"
    | "address1"
    | "address2"
    | "city"
    | "state"
    | "zipCode"
    | "locationType"
    | "deletedAt"
    | "deletedBy"
  >
>;

export type LocationQPSImport = Pick<
  Location,
  "address1" | "address2" | "city" | "state" | "zipCode"
>;

export const LocationStateCodes = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
] as const;

export const locationTypes: readonly LocationType[] = ["SUBSIDIARY"];

export type LocationStateCode = ValueOf<typeof LocationStateCodes>;

export const Territories = [
  "Eastern Pennsylvania",
  "Florida",
  "Great Lakes",
  "Greater New York",
  "Illinois",
  "Lower Midwest",
  "Mid-Atlantic",
  "Mid-Central",
  "New England",
  "Northern California",
  "Northern Texas",
  "Pacific Northwest",
  "Southeast",
  "Souther California",
  "Southern Texas",
  "Southwest",
  "Upper Midwest",
] as const;

export type Territory = ValueOf<typeof Territories>;

export const disabilityStateCodes = ["NY", "NJ", "HI"] as const;
export type DisabilityStateCode = Extract<LocationStateCode, ValueOf<typeof disabilityStateCodes>>;

export const locationsAreEqual = (
  l1: LocationInput | null | undefined,
  l2: LocationInput | null | undefined,
): boolean => {
  if (l1 === undefined || l2 === undefined) return true;
  if (!l1 || !l2) return l1 === l2;
  const isEqual = getHasChangesByKeyList<LocationInput>([
    "name",
    "address1",
    "address2",
    "city",
    "state",
    "zipCode",
  ]);
  return !isEqual(l1, l2).hasChanged;
};

export function getIsLocationStateCode(code: string): code is LocationStateCode {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isLocationStateCode = LocationStateCodes.includes(code as LocationStateCode);
  return isLocationStateCode;
}

export const getIsDisabilityStateCode = (code: string): code is DisabilityStateCode => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const isDisabilityStateCode = disabilityStateCodes.includes(code as DisabilityStateCode);
  return isDisabilityStateCode;
};

const editableLocationFields: (keyof LocationInput)[] = [
  "name",
  "address1",
  "address2",
  "city",
  "state",
  "zipCode",
  "locationType",
];

export const relevantChangesForLocationFields = (
  locationIds: LocationId[],
  changeSnapshot: DEIFChangeSnapshot,
  locationFields: (keyof LocationInput)[] = editableLocationFields,
) => {
  const locationChangeDetailInfoList = locationIds
    .flatMap((locationId) => {
      if (!changeSnapshot.Location[locationId]) return [];

      const changeDetailRecords = locationFields.map((fieldName) => {
        const changeDetailRecord = changeSnapshot.Location[locationId]?.[fieldName];
        return changeDetailRecord;
      });

      return changeDetailRecords;
    })
    .filter(rejectNullableValues);

  return locationChangeDetailInfoList;
};
