import "client/src/polyfills";
import "@ant-design/v5-patch-for-react-19";

import { App } from "client/src/App";
import { initYourBenefitsExplorer } from "client/src/utils/benefits-explorer";
import { getRootElement } from "client/src/utils/getRootElement";
import { initHojar } from "client/src/utils/hotjar";
import { initOnboard } from "client/src/utils/onboard";
import { handlePreloadErrors } from "client/src/utils/preloadError";
import { initSentry } from "client/src/utils/sentry";
import React from "react";
import ReactDOM from "react-dom/client";

handlePreloadErrors();
initOnboard();
initYourBenefitsExplorer();
initSentry();
initHojar();

function renderApp() {
  const container = getRootElement();
  if (!container) {
    throw new Error(`Root element not found`);
  }
  const root = ReactDOM.createRoot(container);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

renderApp();
