import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Skeleton } from "antd";
import { Button } from "client/src/components/Button/Button";

import { HorizontalDivider } from "client/src/components/HorizontalDivider/HorizontalDivider";
import { SlobPopover, usePopoverRef } from "client/src/components/SlobPopover/SlobPopover";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3, Body5 } from "client/src/components/Typography/Typography";
import { RouteData } from "shared/config/routeData";

import * as styles from "./navItemPopover.module.less";

import type { logOutType } from "client/src/pages/Navigation/NavBarPage";
import type { ClientId } from "shared/types/Client";

type NavItemPopoverProps = {
  track?: (buttonLabel: string) => void;
  clientId?: ClientId;
  clientName?: string | null;
  userName: string;
  email: string | undefined;
  logOut: logOutType;
};

export function NavItemPopover(props: NavItemPopoverProps) {
  const { clientId, clientName, userName, email, logOut } = props;

  const [popoverRef, setOpen] = usePopoverRef();

  if (!userName) {
    return (
      <Skeleton
        active
        paragraph={{ width: 90, rows: 1 }}
        title={false}
        className={styles.navLinkSkeleton}
      />
    );
  }

  return (
    <SlobPopover
      popoverRef={popoverRef}
      variant="secondary"
      content={
        <div>
          <StackY dist={8} wrap={false}>
            {clientName && <Body3 as="div">{clientName}</Body3>}
            <Body3 as="div">{userName}</Body3>
            {email && <Body5 as="div">{email}</Body5>}
          </StackY>

          <HorizontalDivider />

          <StackY dist={12}>
            {clientId && (
              <Button
                type="text-only-link"
                size="middle"
                to={RouteData.clientTaskDetail.getPath(clientId, "company-profile")}
                onClick={() => setOpen(false)}
              >
                Company profile
              </Button>
            )}

            <Button type="text-only-link" size="middle" onClick={() => logOut({ data: {} })}>
              Sign Out
            </Button>
          </StackY>
        </div>
      }
    >
      <Button size="middle" type="text-only-link" icon={<FontAwesomeIcon icon={faChevronDown} />}>
        {userName}
      </Button>
    </SlobPopover>
  );
}
