import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Anchor } from "client/src/components/Anchor/Anchor";
import { Button } from "client/src/components/Button/Button";
import { Loading } from "client/src/components/Loading/Loading";
import { clientIsNotAccessibleError } from "client/src/hooks/client";
import { useTrackElementClicked } from "client/src/utils/analytics";
import { vitePreloadError } from "client/src/utils/preloadError";
import { openZendeskChat } from "client/src/utils/zendesk";
import clsx from "clsx";
import { useCallback, useEffect } from "react";

import { RouteData } from "shared/config/routeData";

import * as styles from "./loadingError.module.less";
import type { JSX, ReactNode } from "react";

type ErrorFooterProps = {
  onClickSupport: React.MouseEventHandler<HTMLAnchorElement>;
  onClickChat: React.MouseEventHandler<HTMLElement>;
};

const ErrorFooter = ({ onClickSupport, onClickChat }: ErrorFooterProps) => (
  <p>
    Need more help?{" "}
    <Button type="text-only-link-light" size="middle" onClick={onClickChat}>
      Chat with us
    </Button>{" "}
    or email{" "}
    <Anchor
      variant="bodyText"
      href={`mailto:support@onboard.sunlife.com `}
      onClick={onClickSupport}
    >
      support@onboard.sunlife.com
    </Anchor>
  </p>
);

type LoadingErrorProps = {
  title?: string | JSX.Element | ReactNode;
  error?: unknown;
  type: "page" | "component" | "404" | "custom";
  childElement?: JSX.Element;
};

export const LoadingError = ({
  error,
  type,
  title = "Something went wrong",
  childElement,
}: LoadingErrorProps) => {
  const trackElementClicked = useTrackElementClicked();
  const track = useCallback(
    (buttonLabel: string) => {
      trackElementClicked({
        module: "Error page",
        buttonLabel,
      });
    },
    [trackElementClicked],
  );

  const startChatClick = useCallback(() => {
    track("Start a Chat");
    openZendeskChat();
  }, [track]);

  const isNotAccessibleError = clientIsNotAccessibleError(error);

  // eslint-disable-next-line use-encapsulation/prefer-custom-hooks -- .
  useEffect(() => {
    if (isNotAccessibleError) {
      // We might be rendering this LoadingError component from
      // outside the <Router>, so <Redirect /> won't work.
      window.location.href = RouteData.maintenance.getPath();
    }
  }, [isNotAccessibleError]);

  if (isNotAccessibleError) {
    return null;
  }

  // If there was a vite preload error just keep rendering the
  // Loading screen instead of the Error screen because we are
  // already hard-refreshing the page
  if (vitePreloadError) {
    return <Loading />;
  }

  switch (type) {
    case "component": {
      return (
        <span className={clsx(styles.component, "stack-x-8")}>
          <ExclamationCircleOutlined />
          <span>{title}</span>
        </span>
      );
    }
    case "page": {
      return (
        <div className={styles.page}>
          <h1>Oops! Something went wrong.</h1>
          <p>
            Please refresh the page and try again. If that doesn’t help, one of these steps may help
            to resolve the issue:
          </p>
          <p>
            1. Make sure you have any pop-up or ad blockers disabled. <br />
            2. Use the latest version of Chrome, Safari, Edge, or Firefox. <br />
            3. To clear your cache in Chrome, see Google’s help page here.
          </p>
          <ErrorFooter
            onClickChat={startChatClick}
            onClickSupport={() => track("Mail to support")}
          />
        </div>
      );
    }
    case "404": {
      return (
        <div className={styles.page}>
          <h1>Sorry, the page you are looking for does not exist.</h1>
          <p>Please check the URL or return to Home.</p>
          <div className="mb-48">
            <Button
              type="primary"
              size="large"
              to={RouteData.index.getPathTemplate()}
              onClick={() => track("Return to home")}
            >
              Return to Home
            </Button>
          </div>
          <ErrorFooter
            onClickChat={startChatClick}
            onClickSupport={() => track("Mail to support")}
          />
        </div>
      );
    }
    case "custom": {
      return (
        <div className={styles.page}>
          {childElement}
          <ErrorFooter
            onClickChat={startChatClick}
            onClickSupport={() => track("Mail to support")}
          />
        </div>
      );
    }
  }
};
