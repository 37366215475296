import clsx from "clsx";
import { Link, NavLink } from "react-router";

import * as styles from "./slobLink.module.less";

import type { LinkProps, NavLinkProps } from "react-router";

export type SlobLinkProps = Omit<LinkProps, "className"> & {
  variant?: "bold" | "normal" | "boldSmall";
};

export function SlobLink(props: SlobLinkProps) {
  const variant = props.variant ?? "bold";
  const className = clsx([
    styles.slobLink,
    variant === "bold" && styles.boldVariant,
    variant === "boldSmall" && styles.boldSmallVariant,
    variant === "normal" && styles.normalVariant,
  ]);
  return <Link {...props} className={className} />;
}

type SlobNavLinkProps = Omit<NavLinkProps, "className" | "activeClassName"> & {
  variant?: "bold" | "normal";
};

export function SlobNavLink(props: SlobNavLinkProps) {
  const variant = props.variant ?? "bold";
  const className = clsx([
    styles.slobLink,
    variant === "bold" && styles.boldVariant,
    variant === "normal" && styles.normalVariant,
  ]);

  return (
    <NavLink
      {...props}
      className={({ isActive }) =>
        isActive ? clsx(className, styles.slobNavLink__active) : className
      }
    />
  );
}
