import { Skeleton } from "antd";
import { Col, Row } from "client/src/components/Grid/Grid";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { NavigationHeader } from "client/src/components/NavigationHeader/NavigationHeader";
import { PageContent } from "client/src/components/PageContent/PageContent";
import { StackY } from "client/src/components/Spacing/Spacing";
import { StepNavigationSkeleton } from "client/src/components/StepNavigation/StepNavigationSkeleton";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { eifStepIdMap } from "shared/types/EIF";
import * as styles from "./EIFStepPage.module.less";

export function EIFStepPageLoading() {
  const { eifStepId } = useClientHubParams(["eifStepId"]);

  const stepName = eifStepIdMap[eifStepId];

  return (
    <div className={styles.content}>
      <NavigationHeader backTo={null} title={stepName} />

      <PageContent>
        <Row gutter={64} wrap={false} justify="center">
          <Col span={8}>
            <HubCard padding="none">
              <div className="py-24">
                <StepNavigationSkeleton items={8} />
              </div>
            </HubCard>
          </Col>

          <Col span={16}>
            <EIFStepPageContentsLoading />
          </Col>
        </Row>
      </PageContent>
    </div>
  );
}

export function EIFStepPageContentsLoading() {
  return (
    <div data-testid="loading">
      <StackY dist={32}>
        <Skeleton active title={{ width: "60%" }} paragraph={false} />

        <HubCard>
          <StackY dist={32}>
            <Skeleton active paragraph={{ rows: 3 }} />
            <Skeleton active title={false} paragraph={{ rows: 3 }} />
          </StackY>
        </HubCard>
        <HubCard>
          <StackY dist={32}>
            <Skeleton active paragraph={{ rows: 3 }} />
            <Skeleton active title={false} paragraph={{ rows: 3 }} />
          </StackY>
        </HubCard>
      </StackY>
    </div>
  );
}
