// eslint-disable-next-line eslint-comments/disable-enable-pair -- disable
/* eslint-disable use-encapsulation/prefer-custom-hooks -- -- */
import { Body2, Body3 } from "client/src/components/Typography/Typography";
import { slobMessage } from "client/src/components/slobMessage/slobMessage";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { socketClient } from "client/src/hooks/useWebSocket";
import { useEffect, useRef } from "react";
import { Link } from "react-router";
import { RouteData } from "shared/config/routeData";

export function EmailSentNotification() {
  const { clientId } = useClientHubParams();

  const [api, contextHolder] = slobMessage.useMessage();
  const apiRef = useRef(api);

  useEffect(() => {
    if (!clientId) return;

    socketClient.on("EMAIL_SENT", async function ({ emailId, subject }) {
      await apiRef.current.open({
        type: "info",
        content: (
          <>
            <Body3 white as="p">
              The following email was just sent. <br />
              Click{" "}
              <Link
                to={RouteData.emailClient.getPath(clientId, emailId)}
                style={{ color: "white", textDecoration: "underline", fontWeight: "bold" }}
              >
                here
              </Link>{" "}
              to view it.
            </Body3>

            <Body2 white>{subject}</Body2>
          </>
        ),
        duration: 3,
        style: { maxWidth: "500px", margin: "0 auto" },
      });
    });

    return () => {
      socketClient.off("EMAIL_SENT");
    };
  }, [clientId]);

  return contextHolder;
}
