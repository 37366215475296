import { useQueryClient } from "@tanstack/react-query";
import { invalidateDEIFChanges } from "client/src/hooks/changeLogs";
import { jsonClassPlanToClassPlan } from "client/src/hooks/employeeClassPlan";
import { jsonQPSClassToQPSClass } from "client/src/hooks/qps";
import {
  compareQueryKey,
  ResponseError,
  useSlobMutation,
  useSlobQuery,
} from "client/src/hooks/query";

import type { JsonToTypeMapper } from "client/src/hooks/query";

import type { ClientId } from "shared/types/Client";
import type {
  EmployeeClass,
  EmployeeClassId,
  CreateEmployeeClassInput,
  UpdateEmployeeClassInput,
  DuplicateEmployeeClassInput,
} from "shared/types/EmployeeClass";

export const jsonClassToClass: JsonToTypeMapper<EmployeeClass> = (employeeClass) => {
  return {
    ...employeeClass,
    createdAt: new Date(employeeClass.createdAt),
    updatedAt: new Date(employeeClass.updatedAt),
    deletedAt: employeeClass.deletedAt ? new Date(employeeClass.deletedAt) : null,
    employeeClassPlans: employeeClass.employeeClassPlans.map((ecp) =>
      jsonClassPlanToClassPlan(ecp),
    ),
    qpsClasses: employeeClass.qpsClasses.map((qpsClass) => jsonQPSClassToQPSClass(qpsClass)),
  };
};

export function useGetClasses(
  clientId: ClientId,
  { enabled }: { enabled: boolean } = { enabled: true },
) {
  const query = useSlobQuery<EmployeeClass[]>({
    method: "get",
    path: `/api/clients/${clientId}/employee-classes`,
    map: (ec) => ec.map(jsonClassToClass),
    options: {
      enabled,
    },
  });

  return query;
}

export type GetClassQuery = Pick<ReturnType<typeof useGetClass>, "isPending" | "data">;

export function useGetClass(clientId: ClientId, employeeClassId: EmployeeClassId | undefined) {
  const query = useSlobQuery<EmployeeClass>({
    method: "get",
    path: `/api/clients/${clientId}/employee-classes/${employeeClassId}`,
    map: jsonClassToClass,
    options: {
      enabled: !!clientId && !!employeeClassId,
    },
  });

  return query;
}

export type CreateClassQuery = Pick<
  ReturnType<typeof useCreateClass>,
  "isPending" | "mutateAsync" | "error"
>;

export function useCreateClass() {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    CreateEmployeeClassInput,
    EmployeeClass,
    `/api/clients/:clientId/employee-classes`,
    ResponseError,
    unknown
  >({
    method: "post",
    path: `/api/clients/:clientId/employee-classes`,
    map: jsonClassToClass,
    options: {
      async onSuccess({ data: employeeClass }, { params: { clientId } }) {
        queryClient.setQueryData(
          ["get", `/api/clients/${clientId}/employee-classes/${employeeClass.id}`],
          employeeClass,
        );
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/employee-classes`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
      async onError(responseError, { params: { clientId } }) {
        if (ResponseError.isSlobServerError(responseError.data)) {
          // We get this error code when the client doesn't need multiple classes,
          // but we attempted to create a new one when one already existed.
          // In this case, we refresh the local cache of classes which will cause the
          // user to be redirected to the classes list if they're in the classes settings page
          if (responseError.data.code === "ALREADY_HAS_CLASSES") {
            await Promise.all([
              queryClient.invalidateQueries({
                predicate: compareQueryKey(["get", `/api/clients/${clientId}`]),
              }),
              queryClient.invalidateQueries({
                predicate: compareQueryKey(["get", `/api/clients/${clientId}/employee-classes`]),
              }),
            ]);
          }
        }
      },
    },
  });

  return query;
}

export type UpdateClassQuery = Pick<ReturnType<typeof useUpdateClass>, "isPending" | "mutateAsync">;

export function useUpdateClass() {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    UpdateEmployeeClassInput,
    EmployeeClass,
    `/api/clients/:clientId/employee-classes/:employeeClassId`,
    ResponseError,
    unknown
  >({
    method: "put",
    path: `/api/clients/:clientId/employee-classes/:employeeClassId`,
    map: jsonClassToClass,
    options: {
      async onSuccess({ data: employeeClass }, { params: { clientId } }) {
        queryClient.setQueryData(
          ["get", `/api/clients/${clientId}/employee-classes/${employeeClass.id}`],
          employeeClass,
        );

        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/employee-classes`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey([
              "get",
              `/api/clients/${clientId}/employee-classes/${employeeClass.id}`,
            ]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey([
              "get",
              `/api/clients/${clientId}/additional-compensations`,
            ]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });

  return query;
}

export type DeleteClassFunc = ReturnType<typeof useDeleteClass>["mutateAsync"];

export function useDeleteClass() {
  const queryClient = useQueryClient();

  return useSlobMutation({
    method: "delete",
    path: `/api/clients/:clientId/employee-classes/:employeeClassId`,
    options: {
      async onSuccess(_data, { params: { clientId } }) {
        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/employee-classes`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
}

export type DuplicateEmployeeClassQuery = Pick<
  ReturnType<typeof useDuplicateEmployeeClass>,
  "isPending" | "mutateAsync"
>;

export function useDuplicateEmployeeClass() {
  const queryClient = useQueryClient();

  return useSlobMutation<
    DuplicateEmployeeClassInput,
    EmployeeClass,
    `/api/clients/:clientId/employee-classes/:employeeClassId/duplicate`
  >({
    method: "post",
    path: `/api/clients/:clientId/employee-classes/:employeeClassId/duplicate`,
    map: jsonClassToClass,
    options: {
      async onSuccess({ data: employeeClass }, { params: { clientId } }) {
        queryClient.setQueryData(
          ["get", `/api/clients/${clientId}/employee-classes/${employeeClass.id}`],
          employeeClass,
        );

        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/employee-classes`]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });
}
