import { keepPreviousData, useQueryClient } from "@tanstack/react-query";
import {
  compareQueryId,
  compareQueryKey,
  useSlobMutation,
  useSlobQuery,
} from "client/src/hooks/query";
import { getTableQueryArguments } from "client/src/hooks/slobTable";
import { jsonUserToDomainUser } from "client/src/hooks/user";
import { getURLSearchParamsFromObject } from "client/src/utils/url";
import type { QueryClient } from "@tanstack/react-query";
import type { JsonToTypeMapper } from "client/src/hooks/query";
import type { TableQueryParams } from "client/src/hooks/slobTable";
import type { EmailId, EmailSimple, EmailWithStatusHistory } from "shared/types/Email";

const jsonEmailSimpleToEmailSimple: JsonToTypeMapper<EmailSimple> = (email) => {
  const emailErrorUsersWithCorrectDate = email.errorUsers.map((errorUser) => {
    const errorUserWithUserConvertedToDomainUser = {
      userId: errorUser.userId,
      user: jsonUserToDomainUser(errorUser.user),
    };
    return errorUserWithUserConvertedToDomainUser;
  });
  return {
    ...email,
    createdAt: new Date(email.createdAt),
    errorUsers: emailErrorUsersWithCorrectDate,
  };
};

const jsonEmailToEmail: JsonToTypeMapper<EmailWithStatusHistory> = (email) => {
  return {
    ...email,
    createdAt: new Date(email.createdAt),
    updatedAt: new Date(email.updatedAt),
    deletedAt: email.deletedAt ? new Date(email.deletedAt) : null,
    statusHistory: email.statusHistory.map((s) => ({
      ...s,
      eventAt: s.eventAt ? new Date(s.eventAt) : null,
      createdAt: new Date(s.createdAt),
      updatedAt: new Date(s.updatedAt),
      deletedAt: s.deletedAt ? new Date(s.deletedAt) : null,
    })),
    errorUsers: email.errorUsers.map((e) => ({
      userId: e.userId,
      user: jsonUserToDomainUser(e.user),
    })),
  };
};

export function useGetEmail(emailId: EmailId) {
  const query = useSlobQuery<EmailWithStatusHistory>({
    method: "get",
    path: `/api/emails/${emailId}`,
    map: jsonEmailToEmail,
  });

  return query;
}

type GetEmailsPayload = { data: EmailSimple[]; meta: { count: number } };
type GetEmailsCountPayload = { count: number };

const GET_EMAILS_QUERY_ID = "get-emails";

export function useGetAllEmails(queryParams: TableQueryParams) {
  const params = getTableQueryArguments(queryParams);
  const query = useSlobQuery<GetEmailsPayload>({
    method: "get",
    path: `/api/emails?${params.toString()}`,
    queryId: GET_EMAILS_QUERY_ID,
    map: (r) => ({
      ...r,
      data: r.data.map(jsonEmailSimpleToEmailSimple),
    }),
    options: {
      placeholderData: keepPreviousData,
    },
  });
  return query;
}

export async function invalidateGetEmailsQuery(queryClient: QueryClient) {
  await queryClient.invalidateQueries({
    predicate: compareQueryId(GET_EMAILS_QUERY_ID),
  });
}

const GET_EMAILS_COUNT = "get-emails-count";

export function useGetEmailsCount(queryParams: { clientId?: string; errorsOnly: boolean }) {
  const params = getURLSearchParamsFromObject(queryParams);
  const query = useSlobQuery<GetEmailsCountPayload>({
    method: "get",
    path: `/api/emails/count?${params.toString()}`,
    queryId: GET_EMAILS_COUNT,
    map: (r) => ({
      ...r,
    }),
    options: {
      placeholderData: keepPreviousData,
    },
  });
  return query;
}

export type ResendEmailFunc = ReturnType<typeof useResendEmail>["mutateAsync"];

export function useResendEmail() {
  const queryClient = useQueryClient();

  const query = useSlobMutation<void, string, `/api/emails/:emailId/resend`>({
    method: "post",
    path: `/api/emails/:emailId/resend`,
    map: (id) => id,
    options: {
      onSuccess: async (_response, { params: { emailId } }) => {
        await Promise.all([
          invalidateGetEmailsQuery(queryClient),
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/emails/${emailId}`]),
          }),
        ]);
      },
    },
  });
  return query;
}
