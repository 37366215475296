import { NavBar } from "client/src/components/NavBar/NavBar";
import { SlobNavLink } from "client/src/components/SlobLink/SlobLink";
import { StackX } from "client/src/components/Spacing/Spacing";
import { useSlobAuth } from "client/src/hooks/auth";
import { NavItemPopover } from "client/src/pages/Navigation/NavItemPopover";
import { RouteData } from "shared/config/routeData";
import type { logOutType } from "client/src/pages/Navigation/NavBarPage";

type BrokerPageProps = {
  logOut: logOutType;
  clientId?: string;
};

export const BrokerNavPage = ({ logOut, clientId }: BrokerPageProps) => {
  const { authUser } = useSlobAuth();

  const userName = authUser?.name || "";

  const rightLinks = (
    <StackX dist={48}>
      <SlobNavLink key={RouteData.homeInternal.friendlyName} to={RouteData.homeInternal.getPath()}>
        {RouteData.homeInternal.friendlyName}
      </SlobNavLink>

      <NavItemPopover userName={userName} email={authUser?.email} logOut={logOut} />
    </StackX>
  );

  return (
    <NavBar
      homeLink={RouteData.homeInternal.getPath()}
      clientId={clientId}
      rightLinks={rightLinks}
    />
  );
};
