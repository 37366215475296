import { viteConfig } from "client/src/utils/viteConfig";
import { useEffect, useState } from "react";
import { RouteData } from "shared/config/routeData";

import { getHostEnvironment } from "shared/utils/utils";
import slugify from "slugify";
import type { BenefitTypeBenEx } from "@prisma/client";
import type { Namespace, TFunction } from "i18next";
import type React from "react";
import type { DocumentLanguage } from "shared/types/Document";
import type {
  ExplorerPage,
  ExplorerPageKey,
  PlanSummaryDocumentByBenefitType,
} from "shared/types/ExplorerPage";
import type { PlanType } from "shared/types/ExplorerPageBenefit";
import type { ShowPlanComparisonFields } from "shared/types/Plan";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export const getExplorerPageURL = (
  pageKey: ExplorerPageKey,
  slug = false,
  customBranded = false,
) => {
  let url: string;
  const normalizedPageKey = slug && pageKey ? slugify(pageKey, { lower: true }) : pageKey;
  if (customBranded) {
    const baseUrl = viteConfig.VITE_BENEFITS_EXPLORER_BASE_URL.startsWith(window.location.protocol)
      ? viteConfig.VITE_BENEFITS_EXPLORER_BASE_URL
      : `${window.location.protocol}//${viteConfig.VITE_BENEFITS_EXPLORER_BASE_URL}`;
    url = baseUrl + RouteData.benefitsExplorer.getPath(normalizedPageKey);
  } else {
    url = window.location.origin + RouteData.benefitsExplorerInternal.getPath(normalizedPageKey);
  }

  // ** NOTE **
  // band-aid solution to fix the QA YBE url
  // this is because we build once for all environments and the built artifact is technically
  // built for production. Since the vite public environment variables are set at build time,
  // we have to manually change the url for QA by checking the current host's environment
  const host = window.location.host;
  const environment = getHostEnvironment(host);
  if (environment === "qa" && customBranded && !url.includes("qa-dcs")) {
    url = url.replace("yourbenefitsexplorer.com", "qa-dcs.yourbenefitsexplorer.com");
  }

  return url;
};

export const useWaitForData = (isLoading: boolean) => {
  const [waitForData, setWaitForData] = useState(isLoading);

  useEffect(() => {
    setWaitForData(isLoading);
  }, [isLoading]);

  return waitForData;
};

export const isFavoritePlan = (favoritePlans: string[], planId: string) => {
  if (favoritePlans.length > 0) {
    return !!favoritePlans.find((favPlan) => favPlan === planId);
  }
  return false;
};

/**
 * Create a record with an optional `default` key.
 */
export type CopySwitch<S extends string | number | symbol, T> = { [key in S]?: T } & {
  default?: T;
};

/**
 * Return a copy key given an array of value.
 */
export const copyKey = (...a: (string | number | null | undefined)[]) => {
  return a.filter((val) => (typeof val === "number" ? true : val?.length)).join(".");
};

/**
 * Return true if the given copy key exists.
 */
export const copyKeyExists = (t: TFunction<Namespace<string>>, ...a: Parameters<typeof copyKey>) =>
  // TODO Consider using `i18next.exists(key, options)`
  !!t(copyKey(...a), { defaultValue: null });

export const useCopyKeyExists = (...params: Parameters<typeof copyKeyExists>) => {
  const [exists, setExists] = useState(copyKeyExists(...params));
  useEffect(() => setExists(copyKeyExists(...params)), [params]);
  return exists;
};

export const copyKeySwitch = (
  t: TFunction<Namespace<string>>,
  ...a: Parameters<typeof copyKey>
): string | number => {
  const key = a[a.length - 1];
  return key && copyKeyExists(t, ...a) ? key : "default";
};
export const useCopyKeySwitch = (...params: Parameters<typeof copyKeySwitch>) => {
  const [key, setKey] = useState(copyKeySwitch(...params));
  useEffect(() => setKey(copyKeySwitch(...params)), [params]);
  return key;
};

export const copyKeySwitchCustom = (
  t: TFunction<Namespace<string>>,
  a: Parameters<typeof copyKey>,
  defaultKey: string | number | null,
) => {
  const key = a[a.length - 1];
  return key && copyKeyExists(t, ...a) ? key : defaultKey;
};

export const useIsStuck = (ref: React.RefObject<HTMLElement | null>) => {
  const [stuck, setStuck] = useState(false);

  useEffect(() => {
    const detectIfStuck = () => {
      if (ref.current) {
        const top = parseFloat(getComputedStyle(ref.current).top);
        const topBound = ref.current.getBoundingClientRect().top;
        setStuck(top === topBound);
      }
    };

    detectIfStuck();
    window.addEventListener("scroll", detectIfStuck, { passive: true });
    window.addEventListener("resize", detectIfStuck, { passive: true });

    return () => {
      window.removeEventListener("scroll", detectIfStuck);
      window.removeEventListener("resize", detectIfStuck);
    };
  }, [ref]);

  return stuck;
};

export const getDocumentsListFromAssociatedPages = (
  associatedPages: ExplorerPage[],
  language: DocumentLanguage,
): PlanSummaryDocumentByBenefitType[] => {
  let documents: PlanSummaryDocumentByBenefitType[] = [];
  associatedPages.forEach((explorerPage) => {
    const res = explorerPage.benefits?.reduce<PlanSummaryDocumentByBenefitType[]>((acc, val) => {
      if (val.planSummaryDocument && language === "EN") {
        return acc.concat([
          {
            benType: val.benefitType,
            docs: [
              {
                documentKey: val.planSummaryDocument.documentKey,
                id: val.planSummaryDocument.id,
                name: val.planSummaryDocument.name,
                mimeType: val.planSummaryDocument.mimeType,
              },
            ],
          },
        ]);
      } else if (val.spanishPlanSummaryDocument && language === "ES") {
        return acc.concat([
          {
            benType: val.benefitType,
            docs: [
              {
                documentKey: val.spanishPlanSummaryDocument.documentKey,
                id: val.spanishPlanSummaryDocument.id,
                name: val.spanishPlanSummaryDocument.name,
                mimeType: val.spanishPlanSummaryDocument.mimeType,
              },
            ],
          },
        ]);
      }
      return acc;
    }, []);

    if (res) {
      documents = documents.concat(res);
    }
  });
  return documents;
};

export const getDocumentsListByBenefitType = (allDocuments: PlanSummaryDocumentByBenefitType[]) => {
  const planSummaryDocumentsByBenefitType = allDocuments.reduce<PlanSummaryDocumentByBenefitType[]>(
    (acc, val) => {
      if (!acc.some(({ benType }) => benType === val.benType)) {
        return acc.concat(val);
      }

      const documentKeyToMatch = val.docs[0]?.documentKey;
      if (
        acc.some(({ docs }) => docs.some(({ documentKey }) => documentKey === documentKeyToMatch))
      )
        return acc;

      const documentToPush = val.docs[0];
      const combinedValues = acc.filter((a) => {
        return a.benType === val.benType && a.docs.length < 15 && documentToPush
          ? a.docs.push(documentToPush)
          : a.docs;
      });
      return combinedValues;
    },
    [],
  );
  return planSummaryDocumentsByBenefitType;
};

// pages created before this date are considered "old" and should not have new "plan comparison table fields" feature
export const planComparisonTableBenefitTypeLaunchDates = {
  MEDICAL: new Date("9/15/2024"),
  FINANCIAL: new Date("9/15/2024"),
  DENTAL: new Date("5/30/2024"),
  VISION: new Date("5/30/2024"),
} satisfies Partial<Record<BenefitTypeBenEx, Date>>;

/**
 * Check if the page is new or has a valid date.
 * @param pageCreationDate The date the page was created.
 */
export const isPageNewOrValidDate = (
  pageCreationDate?: Date,
  benefitType?: BenefitTypeBenEx,
): boolean => {
  if (!pageCreationDate) return true;
  switch (benefitType) {
    case "MEDICAL":
      return pageCreationDate >= planComparisonTableBenefitTypeLaunchDates.MEDICAL;
    case "FINANCIAL":
      return pageCreationDate >= planComparisonTableBenefitTypeLaunchDates.FINANCIAL;
    case "DENTAL":
      return pageCreationDate >= planComparisonTableBenefitTypeLaunchDates.DENTAL;
    case "VISION":
      return pageCreationDate >= planComparisonTableBenefitTypeLaunchDates.VISION;
    default:
      return true;
  }
};

export const checkIfShowPlanComparisonFields = (
  pageCreationDate?: Date,
  benefitType?: BenefitTypeBenEx | "",
  planType?: PlanType | null,
  featureToggle?: ClientFeatureToggles,
  isRenewal?: boolean,
  hideNewVisionPlanFields?: boolean,
  hideNewDentalPlanFields?: boolean,
): ShowPlanComparisonFields => {
  if (
    !isPageNewOrValidDate(pageCreationDate, benefitType === "" ? undefined : benefitType) &&
    !isRenewal
  )
    return false;
  else if (benefitType === "MEDICAL" && !!featureToggle?.BENEX_MEDICAL_PLAN_COMPARISON) {
    return "MEDICAL";
  } else if (
    benefitType === "DENTAL" &&
    !!featureToggle?.BENEX_DENTAL_PLAN_COMPARISON &&
    !hideNewDentalPlanFields
  ) {
    if (["DHMO", "DCA"].includes(planType ?? "")) return false;
    return "DENTAL";
  } else if (
    benefitType === "VISION" &&
    !!featureToggle?.BENEX_VISION_PLAN_COMPARISON &&
    !hideNewVisionPlanFields
  ) {
    return "VISION";
  }

  return false;
};
