import { useSlobMutation } from "client/src/hooks/query";

import type { Pretty } from "shared/types/Helper";
import type { leadGenerationRequestValidation } from "shared/validation/explorerPage";
import type { InferType } from "yup";

export const useLeadGeneration = () =>
  useSlobMutation<
    Pretty<InferType<typeof leadGenerationRequestValidation>>,
    boolean,
    "/api/explorer-pages/lead-generation/send"
  >({
    method: "post",
    path: `/api/explorer-pages/lead-generation/send`,
  });
