import { useSlobAuth } from "client/src/hooks/auth";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { useEffect } from "react";
import { getIsInternalUser } from "shared/rbac/rbac";
import { io } from "socket.io-client";
import type { SocketClient } from "shared/types/Socket";

export const socketClient: SocketClient = io("", {
  autoConnect: false,
  reconnectionAttempts: 5,
  transports: ["websocket", "webtransport"],
});

const useSetUpSocketIO = () => {
  const { clientId } = useClientHubParams();
  const { authUser } = useSlobAuth();
  const userId = authUser?.userId;
  const isInternalUser = getIsInternalUser(authUser);

  useEffect(() => {
    if (!userId || !isInternalUser) return;

    socketClient.auth = { clientId, userId };
    socketClient.connect();

    return () => {
      socketClient.disconnect();
    };
  }, [clientId, userId, isInternalUser]);
};

// to be the entrypoint to handle the websocket connection and auth
export const WebSocketApp = () => {
  useSetUpSocketIO();
  return null;
};
