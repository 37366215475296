import clsx from "clsx";

import * as styles from "./horizontalDivider.module.less";

import type { distances } from "client/src/components/Spacing/Spacing";

type HorizontalDividerProps = {
  size?: (typeof distances)[number];
};

export const HorizontalDivider = ({ size = 24 }: HorizontalDividerProps) => (
  <hr className={clsx(`my-${size}`, styles.hr)} />
);
