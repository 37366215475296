import { Spinner } from "client/src/components/Spinner/Spinner";
import clsx from "clsx";

import * as styles from "./loading.module.less";

type LoadingProps = {
  pageHeight?: boolean;
};

export const Loading = ({ pageHeight = true }: LoadingProps) => {
  const className = pageHeight ? clsx(styles.center, styles.pageHeight) : styles.center;
  return (
    <div data-testid="loading" className={className}>
      <Spinner />
    </div>
  );
};

export const BasePageLoading = () => {
  return (
    <div data-testid="base-page-loading">
      <main>
        <Loading />;
      </main>
    </div>
  );
};
