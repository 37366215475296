import * as regIcons from "@fortawesome/free-regular-svg-icons";
import * as solidIcons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProgressBar } from "client/src/components/ProgressBar/ProgressBar";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body5, Body7 } from "client/src/components/Typography/Typography";
import { useToggler } from "client/src/hooks/useToggler";
import clsx from "clsx";
import { flushSync } from "react-dom";
import { Link } from "react-router";
import { RouteData } from "shared/config/routeData";
import * as styles from "./QPSPushProgressIndicator.module.less";
import type { Client } from "shared/types/Client";

type Status = "loading" | "success-with-warnings" | "success" | "error";

export type Props = {
  cases: Array<{
    client: Client;
    status: Status;
  }>;
  onRemove: (client: Client) => void;
};

export function QPSPushProgressIndicator(props: Props) {
  const { cases, onRemove } = props;

  const [collapsed, toggleCollapsed] = useToggler();

  if (cases.length === 0) return null;

  return (
    <div className={styles.qpsPushProgressIndicator}>
      <div className="body2 flex">
        <span style={{ flexGrow: 1 }}>Push to QPS</span>
        <button
          className="btn-reset"
          onClick={toggleCollapsed}
          aria-label={collapsed ? "Expand" : "Collapse"}
          style={{ width: 16 }}
        >
          <FontAwesomeIcon
            icon={collapsed ? solidIcons.faUpRightAndDownLeftFromCenter : solidIcons.faSubtract}
          />
        </button>
      </div>

      <div
        className={clsx(styles.clientsContainer, collapsed && styles.clientsContainer__collapsed)}
      >
        <StackY dist={16} wrap={false}>
          {cases.map(({ client, status }) => (
            <StackY
              dist={4}
              key={client.id}
              wrap={false}
              // View transition names cannot start with a number
              style={{ viewTransitionName: "_" + client.id }}
            >
              <Body7 as="div">{client.name}</Body7>
              <Body5 as="div">Case {client.caseId}</Body5>

              {status === "loading" ? (
                <div className="mt-8">
                  <ProgressBar id={client.id} aria-label="Pushing to QPS" />
                </div>
              ) : (
                <div className={styles.completed}>
                  {status === "success" && (
                    <Body7 as="div" greenMedium>
                      <FontAwesomeIcon icon={solidIcons.faCheckCircle} /> Successfully pushed
                    </Body7>
                  )}

                  {status === "success-with-warnings" && (
                    <Body7 as="div" darkYellow>
                      <FontAwesomeIcon icon={solidIcons.faInfoCircle} /> Pushed with warnings
                    </Body7>
                  )}

                  {status === "error" && (
                    <Body7 as="div" redMedium>
                      <FontAwesomeIcon icon={solidIcons.faWarning} /> Pushed with errors
                    </Body7>
                  )}

                  <Link
                    to={RouteData.clientTaskDetail.getPath(
                      client.id,
                      "eif-submit-company-information",
                    )}
                  >
                    <FontAwesomeIcon icon={regIcons.faCircleRight} />
                    <span className="sr-only">Go to {client.name}</span>
                  </Link>

                  <button
                    className="btn-reset"
                    aria-label={`Remove ${client.name}`}
                    onClick={() =>
                      document.startViewTransition(() => flushSync(() => onRemove(client)))
                    }
                  >
                    <FontAwesomeIcon icon={solidIcons.faClose} />
                  </button>
                </div>
              )}
            </StackY>
          ))}
        </StackY>
      </div>
    </div>
  );
}
