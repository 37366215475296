import { Skeleton } from "antd";
import { BackDynamic } from "client/src/components/Back/Back";
import { AlertBanner } from "client/src/components/Banner/AlertBanner";
import { HubCard } from "client/src/components/HubCard/HubCard";
import { StackY } from "client/src/components/Spacing/Spacing";
import { Body3 } from "client/src/components/Typography/Typography";
import { useClientHubParams } from "client/src/hooks/useClientHubParams";
import { DownloadEIFSummaryPDFLink } from "client/src/pages/EIFSummaryPage/DownloadEIFSummaryPDFLink";
import { PrintHidden } from "client/src/utils/print";
import clsx from "clsx";
import { RouteData } from "shared/config/routeData";

import * as styles from "./EIFSummaryWrapper.module.less";
import type { SignerMode } from "shared/types/OutsideSigner";
import type { EIFEditState } from "shared/utils/EIF/getEIFEditState";

type Props = {
  children: React.ReactNode;
  signerMode: SignerMode;
  eifEditState: EIFEditState;
};

export function EIFSummaryWrapper({ children, signerMode, eifEditState }: Props) {
  const { clientId } = useClientHubParams(["clientId"]);

  return (
    <>
      {signerMode === "inside" && (
        <>
          <header className={clsx(styles.eifSummaryPageColumn, styles.header)}>
            <PrintHidden>
              <div className="mb-12">
                <BackDynamic
                  fallbackTo={RouteData.clientTaskDetail.getPath(
                    clientId,
                    "eif-submit-company-information",
                  )}
                />
              </div>
            </PrintHidden>

            <h1>Company Information Summary</h1>
          </header>
          <div className={clsx(styles.eifSummaryPageContent)}>
            <div className={styles.eifSummaryPageColumn}>
              <div className={styles.eifSummaryPageContentColumn}>{children}</div>
            </div>
          </div>
        </>
      )}
      {signerMode === "outside" && (
        <>
          {eifEditState === "changes-accepted" && (
            <header className={clsx(styles.eifSummaryPageColumn, styles.osHeader)}>
              <StackY dist={32}>
                <h1>Thank you for accepting the updates.</h1>
                <div className={clsx("mr-12", "ml-12")}>
                  <AlertBanner
                    variant="info"
                    message={
                      <Body3>
                        Need a copy of your updated company information?{" "}
                        <DownloadEIFSummaryPDFLink clientId={clientId} />
                      </Body3>
                    }
                  />
                </div>
              </StackY>
            </header>
          )}
          {eifEditState === "changes-declined-no-new-edits" && (
            <header className={clsx(styles.eifSummaryPageColumn, styles.osHeader)}>
              <h1>
                Thank you.
                <br />
                Sun Life is reviewing your responses
              </h1>
            </header>
          )}
          {eifEditState === "ready-for-review" && (
            <header className={clsx(styles.eifSummaryPageColumn, styles.header)}>
              <p>
                Your company information has been reviewed by your Implementation Consultant and
                they made changes to the sections listed below. Please review and accept or decline
                the changes.
              </p>
            </header>
          )}
          <div className={styles.container}>{children}</div>
        </>
      )}
    </>
  );
}

export function EIFSummarySkeleton() {
  return (
    <EIFSummaryWrapper signerMode="inside" eifEditState="no-edits">
      <div className="pb-64">
        <Skeleton title={{ width: "15%" }} paragraph={false} />
        <br />
        <Skeleton title={{ width: "35%" }} paragraph={false} />
        <Skeleton title={{ width: "50%" }} paragraph={false} />
        <Skeleton title={{ width: "75%" }} paragraph={false} />
        <Skeleton title={{ width: "50%" }} paragraph={false} />
        <Skeleton title={{ width: "55%" }} paragraph={false} />

        <hr />

        <StackY dist={32}>
          {Array(5)
            .fill(null)
            .map((_, i) => (
              <div key={i}>
                <Skeleton title={{ width: "25%" }} paragraph={false} className="mb-12" />

                <HubCard>
                  <Skeleton paragraph={{ rows: 3 }} />
                  <Skeleton title={false} paragraph={{ rows: 3 }} />
                </HubCard>
              </div>
            ))}
        </StackY>
      </div>
    </EIFSummaryWrapper>
  );
}
