import { Skeleton } from "antd";
import clsx from "clsx";
import * as styles from "./stepNavigation.module.less";

export function StepNavigationSkeleton({ items }: { items: number }) {
  return (
    <div>
      <div className={clsx(styles.skeleton, "px-24 stack-y-24")}>
        {Array(items)
          .fill(null)
          .map((_, i) => (
            <Skeleton key={i} active title={false} paragraph={{ rows: 1, width: "100%" }} />
          ))}
      </div>
      <hr className="my-16" />
      <div className={clsx(styles.skeleton, "px-24")}>
        <Skeleton active title={false} paragraph={{ rows: 1, width: "50%" }} />
      </div>
    </div>
  );
}
