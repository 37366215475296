import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "client/src/components/Button/Button";
import { SlobPopover, usePopoverRef } from "client/src/components/SlobPopover/SlobPopover";
import { StackY, StackX } from "client/src/components/Spacing/Spacing";
import { RouteData } from "shared/config/routeData";

type UsersNavPopoverProps = {
  showUserInternalLink: boolean;
};

export function UsersNavPopover({ showUserInternalLink }: UsersNavPopoverProps) {
  const [popoverRef, setOpen] = usePopoverRef();

  const onClick = () => setOpen(false);

  return (
    <SlobPopover
      popoverRef={popoverRef}
      variant="secondary"
      content={
        <StackY dist={12}>
          <Button
            type="text-only-link"
            size="middle"
            to={RouteData.brokers.getPath()}
            onClick={onClick}
          >
            Brokers
          </Button>

          {showUserInternalLink && (
            <Button
              type="text-only-link"
              size="middle"
              to={RouteData.usersInternal.getPath()}
              onClick={onClick}
            >
              Internal users
            </Button>
          )}

          <Button
            type="text-only-link"
            size="middle"
            to={RouteData.benAdmins.getPath()}
            onClick={onClick}
          >
            Benefits administrator
          </Button>
        </StackY>
      }
    >
      <Button size="middle" type="text-only-link">
        <StackX dist={8}>
          <span>Users</span>
          <FontAwesomeIcon icon={faChevronDown} />
        </StackX>
      </Button>
    </SlobPopover>
  );
}
