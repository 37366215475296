import { DateTime } from "luxon";
import { RouteData } from "shared/config/routeData";
import { clientTaskIds, policyTaskIds } from "shared/data/Tasks";
import { getAreTherePendingChanges } from "shared/utils/EIF/changeLog";
import { assertIsDefined } from "shared/utils/utils";
import type { ValidParam } from "shared/config/routeData";
import type { TaskId, ClientTaskId, PolicyTaskId } from "shared/data/Tasks";
import type { DEIFChangeSnapshot } from "shared/types/Change";
import type { Client, ClientId, EIFSubStepViewMode, Policy, PolicyId } from "shared/types/Client";
import type { EIFStepId, EIFSubStepId } from "shared/types/EIF";

export const isTaskId = (taskId: TaskId | undefined): taskId is TaskId => {
  return isClientTaskId(taskId) || isPolicyTaskId(taskId);
};

export const getTaskDetailRoute = (
  clientId: ClientId,
  taskId: TaskId,
  policyId: PolicyId | undefined,
) => {
  if (isPolicyTaskId(taskId) && policyId) {
    return RouteData.policyTaskDetail.getPath(clientId, taskId, policyId);
  }
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  return RouteData.clientTaskDetail.getPath(clientId, taskId as ClientTaskId);
};

export const isClientTaskId = (taskId: TaskId | ValidParam | undefined): taskId is ClientTaskId =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disabled
  Boolean(taskId) && clientTaskIds.includes(taskId as ClientTaskId);

export const isPolicyTaskId = (taskId: TaskId | ValidParam | undefined): taskId is PolicyTaskId =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disabled
  Boolean(taskId) && policyTaskIds.includes(taskId as PolicyTaskId);

export const isPeakSeason = (now: DateTime) => {
  const peakSeasonStart = DateTime.fromObject({
    day: 20,
    month: 11,
    year: now.month <= 2 ? now.year - 1 : now.year,
  });
  const peakSeasonEnd = DateTime.fromObject({
    day: 31,
    month: 1,
    year: now.month >= 11 ? now.year + 1 : now.year,
  });

  return (
    now.startOf("day") >= peakSeasonStart.startOf("day") &&
    now.endOf("day") <= peakSeasonEnd.endOf("day")
  );
};

export const getEIFTaskAndStepDetailRoute = (
  clientId: ClientId,
  stepId: EIFStepId,
  subStepId: EIFSubStepId,
) => {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- disable
  return RouteData.eifSubStepDetail.getPath(clientId, stepId, subStepId);
};

export const trimListOfEmails = (listOfEmails: string | null) => {
  if (!listOfEmails) {
    return undefined;
  }
  return listOfEmails
    .trim()
    .split(/[,;\s]+/)
    .map((email) => email.trim())
    .join();
};

export const isAddCoverageClient = (client: Pick<Client, "implementationType">): boolean =>
  client.implementationType === "ADD_COVERAGE";

export const getPrimaryPolicy = <
  T extends { policies: K[] },
  K extends { primaryPolicy: boolean } = Policy,
>(
  client: T,
): K => {
  const primaryPolicy = client.policies.find((p) => p.primaryPolicy);
  assertIsDefined(primaryPolicy, "primaryPolicy");
  return primaryPolicy;
};

export const getPrimaryPolicyId = (client: Client) => {
  const primaryPolicy = getPrimaryPolicy(client);
  return primaryPolicy.id;
};

export function getIsMultiPolicyMode(client: Client) {
  const isMultiPolicyMode = client.policies.length > 1;
  return isMultiPolicyMode;
}

export const getShowValidationErrorsInSummary = (
  client: Client,
  viewMode: EIFSubStepViewMode,
  changeSnapshot: DEIFChangeSnapshot | null,
) => {
  const areTherePendingChanges =
    changeSnapshot === null ? false : getAreTherePendingChanges({ client, changeSnapshot });

  const showValidationErrorsInSummary =
    (viewMode === "post-sign-with-edit" || viewMode === "review-page") && !areTherePendingChanges
      ? false
      : true;

  return showValidationErrorsInSummary;
};

export function getEIFSubStepViewMode(args: { client: Client }): EIFSubStepViewMode {
  const { client } = args;

  if (client.eifSignedAt) {
    return "post-sign-with-edit";
  }

  return "pre-sign";
}
