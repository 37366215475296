import { useReducer } from "react";
import { exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";

type TransitionState = "collapsed" | "expanding" | "expanded" | "collapsing";

type Action = "toggle" | "end";

export function useExpandCollapseTransitionReducer() {
  const [transitionState, setTransitionState] = useReducer(
    (state: TransitionState, action: Action) => {
      switch (action) {
        case "toggle": {
          const stateMachine: Record<TransitionState, TransitionState> = {
            collapsed: "expanding",
            expanding: "collapsing",
            expanded: "collapsing",
            collapsing: "expanding",
          };
          return stateMachine[state];
        }
        case "end": {
          const stateMachine: Record<TransitionState, TransitionState> = {
            collapsed: "collapsed",
            expanding: "expanded",
            expanded: "expanded",
            collapsing: "collapsed",
          };
          return stateMachine[state];
        }
        default:
          exhaustiveCheckFail(action);
      }
    },
    "collapsed",
  );

  return { transitionState, setTransitionState };
}
