/* eslint-disable eslint-comments/disable-enable-pair -- need to access yup context */
/* eslint-disable @typescript-eslint/no-unsafe-member-access -- need to access yup context */

import { BenefitTypesBenEx } from "shared/types/BenefitTypes";
import {
  BenefitContentFlags,
  BenefitLevels,
  BenefitLevelsByBenefit,
  BenefitPlanTypes,
  ContentFlags,
  DentalNetworks,
  DentalStateByPlanType,
  DentalYearTypes,
  PlanTypes,
} from "shared/types/ExplorerPageBenefit";
import { locationStateCodeValidation } from "shared/validation/location";
import * as Yup from "yup";

import type { BenefitTypeBenEx } from "shared/types/BenefitTypes";
import type {
  BenefitLevel,
  ContentFlag,
  PlanType,
  DentalNetwork,
  DentalYearType,
} from "shared/types/ExplorerPageBenefit";
import type { ShowPlanComparisonFields } from "shared/types/Plan";
import type { FeatureToggleValue } from "shared/types/Toggles";

const benefitTypeValidationSchema = Yup.mixed<BenefitTypeBenEx>().oneOf<BenefitTypeBenEx>([
  ...BenefitTypesBenEx,
]);
const planTypeValidationSchema = Yup.mixed<PlanType>().oneOf<PlanType>(PlanTypes).nullable();

const benefitLevelsValidationSchema = Yup.mixed<BenefitLevel>()
  .oneOf<BenefitLevel>(BenefitLevels)
  .nullable();

/* 
Note: The preceding .nullable() before the .when() on the plan comparison table schemas is necessary
for typescript to see the fields as possible nulls `{type} | null | undefined`, otherwise it will only show
as `{type} | undefined`. It doesn't change the behavior of the Yup schema validation in any way since
the .required() inside of the .when()->then overrides the preceding .nullable().
*/

// Use this schema for both medical and dental generic PCT fields
const benefitPlanComparisonTableFields = Yup.object({
  planYearDeductibleIndividualInNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields && ["MEDICAL", "DENTAL"].includes(showPlanComparisonFields),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),

  planYearDeductibleFamilyInNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields && ["MEDICAL", "DENTAL"].includes(showPlanComparisonFields),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),

  planYearMaximumIndividualInNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields && ["MEDICAL", "DENTAL"].includes(showPlanComparisonFields),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
});

const medicalPlanComparisonTableFields = Yup.object({
  planYearDeductibleOutOfNetworkNA: Yup.boolean().default(false),
  planYearMaximumOutOfNetworkNA: Yup.boolean().default(false),
  planYearDeductibleIndividualOutOfNetwork: Yup.number()
    .min(0)
    .nullable()
    .when(["$showPlanComparisonFields", "planYearDeductibleOutOfNetworkNA"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, na: boolean) =>
        showPlanComparisonFields === "MEDICAL" && na === false,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  planYearDeductibleFamilyOutOfNetwork: Yup.number()
    .min(0)
    .nullable()
    .when(["$showPlanComparisonFields", "planYearDeductibleOutOfNetworkNA"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, na: boolean) =>
        showPlanComparisonFields === "MEDICAL" && na === false,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  planYearMaximumIndividualOutOfNetwork: Yup.number()
    .min(0)
    .nullable()
    .when(["$showPlanComparisonFields", "planYearMaximumOutOfNetworkNA"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, na: boolean) =>
        showPlanComparisonFields === "MEDICAL" && na === false,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  planYearMaximumFamilyInNetwork: Yup.number()
    .min(0)
    .nullable()
    .when(["$showPlanComparisonFields"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "MEDICAL",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  planYearMaximumFamilyOutOfNetwork: Yup.number()
    .min(0)
    .nullable()
    .when(["$showPlanComparisonFields", "planYearMaximumOutOfNetworkNA"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, na: boolean) =>
        showPlanComparisonFields === "MEDICAL" && na === false,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  medicalOutOfNetworkBenefits: Yup.boolean()
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "MEDICAL",
      then: (schema) => schema.required(),
    }),
  medicalHealthSavingsAccountEmployerContribution: Yup.boolean()
    .nullable()
    .when(["$showPlanComparisonFields", "contentFlags"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, contentFlags: ContentFlag[]) =>
        showPlanComparisonFields === "MEDICAL" &&
        Array.isArray(contentFlags) &&
        contentFlags.includes("HSA compatible"),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  medicalPcpCoordinationRequired: Yup.boolean()
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "MEDICAL",
      then: (schema) => schema.required(),
    }),
  medicalPcpSpecialtyCareReferralRequired: Yup.boolean()
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "MEDICAL",
      then: (schema) => schema.required(),
    }),
});

const dentalNetworksValidationSchema = Yup.mixed<DentalNetwork>()
  .oneOf<DentalNetwork>(DentalNetworks)
  .nullable();

const dentalPlanComparisonTableFields = Yup.object({
  dentalYearType: Yup.mixed<DentalYearType>()
    .oneOf<DentalYearType>(DentalYearTypes)
    .nullable()
    .when(["$showPlanComparisonFields", "planType"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, planType: PlanType | null) =>
        !["DHMO", "DCA"].includes(planType ?? "") && showPlanComparisonFields === "DENTAL",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  dentalOrthodonticServices: Yup.string()
    .nullable()
    .when(["$showPlanComparisonFields", "planType"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, planType: PlanType | null) =>
        !["DHMO", "DCA"].includes(planType ?? "") && showPlanComparisonFields === "DENTAL",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  dentalOrthodonticMaximumAdult: Yup.number()
    .min(0)
    .nullable()
    .when(["$showPlanComparisonFields", "planType", "dentalOrthodonticServices"], {
      is: (
        showPlanComparisonFields: ShowPlanComparisonFields,
        planType: PlanType | null,
        dentalOrthodonticServices?: string | null,
      ) =>
        showPlanComparisonFields === "DENTAL" &&
        !["DHMO", "DCA"].includes(planType ?? "") &&
        dentalOrthodonticServices &&
        ["Adults", "Both"].includes(dentalOrthodonticServices),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  dentalOrthodonticMaximumAdultPercent: Yup.number()
    .min(0)
    .nullable()
    .when(["$showPlanComparisonFields", "planType", "dentalOrthodonticServices"], {
      is: (
        showPlanComparisonFields: ShowPlanComparisonFields,
        planType: PlanType | null,
        dentalOrthodonticServices?: string | null,
      ) =>
        showPlanComparisonFields === "DENTAL" &&
        ["ASO_PPO", "PPO", "INDEMNITY"].includes(planType ?? "") &&
        dentalOrthodonticServices &&
        ["Adults", "Both"].includes(dentalOrthodonticServices),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  dentalOrthodonticMaximumChild: Yup.number()
    .min(0)
    .nullable()
    .when(["$showPlanComparisonFields", "planType", "dentalOrthodonticServices"], {
      is: (
        showPlanComparisonFields: ShowPlanComparisonFields,
        planType: PlanType | null,
        dentalOrthodonticServices?: string | null,
      ) =>
        showPlanComparisonFields === "DENTAL" &&
        !["DHMO", "DCA"].includes(planType ?? "") &&
        dentalOrthodonticServices &&
        ["Children", "Both"].includes(dentalOrthodonticServices),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  dentalOrthodonticMaximumChildPercent: Yup.number()
    .min(0)
    .nullable()
    .when(["$showPlanComparisonFields", "planType", "dentalOrthodonticServices"], {
      is: (
        showPlanComparisonFields: ShowPlanComparisonFields,
        planType: PlanType | null,
        dentalOrthodonticServices?: string | null,
      ) =>
        showPlanComparisonFields === "DENTAL" &&
        ["ASO_PPO", "PPO", "INDEMNITY"].includes(planType ?? "") &&
        dentalOrthodonticServices &&
        ["Children", "Both"].includes(dentalOrthodonticServices),
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  dentalTypeOneInNetwork: Yup.number()
    .min(0)
    .max(100)
    .nullable()
    .when(["$showPlanComparisonFields", "planType"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, planType?: string | null) =>
        showPlanComparisonFields === "DENTAL" && planType !== "DHMO",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  dentalTypeOneOutOfNetwork: Yup.number()
    .min(0)
    .max(100)
    .nullable()
    .when(["$showPlanComparisonFields", "planType"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, planType?: string | null) =>
        showPlanComparisonFields === "DENTAL" && planType !== "DHMO",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  dentalTypeTwoInNetwork: Yup.number()
    .min(0)
    .max(100)
    .nullable()
    .when(["$showPlanComparisonFields", "planType"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, planType?: string | null) =>
        showPlanComparisonFields === "DENTAL" && planType !== "DHMO",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  dentalTypeTwoOutOfNetwork: Yup.number()
    .min(0)
    .max(100)
    .nullable()
    .when(["$showPlanComparisonFields", "planType"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, planType?: string | null) =>
        showPlanComparisonFields === "DENTAL" && planType !== "DHMO",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  dentalTypeThreeInNetwork: Yup.number()
    .min(0)
    .max(100)
    .nullable()
    .when(["$showPlanComparisonFields", "planType"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, planType?: string | null) =>
        showPlanComparisonFields === "DENTAL" && planType !== "DHMO",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  dentalTypeThreeOutOfNetwork: Yup.number()
    .min(0)
    .max(100)
    .nullable()
    .when(["$showPlanComparisonFields", "planType"], {
      is: (showPlanComparisonFields: ShowPlanComparisonFields, planType?: string | null) =>
        showPlanComparisonFields === "DENTAL" && planType !== "DHMO",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
});

const visionPlanComparisonTableFields = Yup.object({
  visionExamServicesBiYearly: Yup.boolean()
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionExamServicesInNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionExamServicesOutOfNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionLensesBiYearly: Yup.boolean()
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionLensesInNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionLensesOutOfNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionFramesBiYearly: Yup.boolean()
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionFramesInNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionFramesOutOfNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionFramesOverInNetwork: Yup.number()
    .min(0)
    .max(100)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionFramesOverOutOfNetwork: Yup.number()
    .min(0)
    .max(100)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionContactLensesBiYearly: Yup.boolean()
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionContactLensesInNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionContactLensesOutOfNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionContactLensesExamInNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  visionContactLensesExamOutOfNetwork: Yup.number()
    .min(0)
    .nullable()
    .when("$showPlanComparisonFields", {
      is: (showPlanComparisonFields: ShowPlanComparisonFields) =>
        showPlanComparisonFields === "VISION",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
});

const fsaRolloverFields = Yup.object({
  financialFSAMoneyRollover: Yup.boolean()
    .nullable()
    .when("$showFSARolloverFields", {
      is: (showFSARolloverFields: boolean) => showFSARolloverFields,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  financialFSAMoneyRolloverAmount: Yup.number()
    .min(0)
    .nullable()
    .when(["$showFSARolloverFields", "financialFSAMoneyRollover"], {
      is: (showFSARolloverFields: boolean, financialFSAMoneyRollover: FeatureToggleValue) =>
        showFSARolloverFields && financialFSAMoneyRollover,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  financialFSAMoneyRolloverDate: Yup.date()
    .nullable()
    .when(["$showFSARolloverFields", "financialFSAMoneyRollover"], {
      is: (showFSARolloverFields: boolean, financialFSAMoneyRollover: FeatureToggleValue) =>
        showFSARolloverFields && financialFSAMoneyRollover,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
});

export const explorerPageBenefitInputValidation = Yup.object({
  planSummaryDocumentId: Yup.string().optional().nullable(),
  carrierId: Yup.number().moreThan(0).required(),
  benefitType: benefitTypeValidationSchema.required(),
  planType: planTypeValidationSchema.nullable().test({
    name: "plan-type-validation",
    test: (value, context) => {
      const planTypes =
        BenefitPlanTypes.get(
          // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need to cast here
          context.parent.benefitType as BenefitTypeBenEx,
        ) ?? null;

      return value ? planTypes?.includes(value) ?? false : planTypes === null;
    },
  }),
  isNew: Yup.boolean().required(),
  id: Yup.string().optional().nullable(),
  legalEntity: Yup.string()
    .trim()
    .nullable()
    .when("carrierId", {
      is: (carrierId: number) => {
        return carrierId !== 1;
      },
      then: (schema) => schema.required("Underwriter/Administrator is required"),
      otherwise: (schema) => schema.trim().optional().nullable(),
    }),
  planName: Yup.string().trim().nullable().required("Plan Name is required."),
  contentFlags: Yup.array()
    .of(
      Yup.mixed<ContentFlag>()
        .oneOf<ContentFlag>([...ContentFlags])
        .required(),
    )
    .nullable()
    .test({
      message: "Invalid content flags.",
      name: "content-flags-validation",
      test: (value, context) => {
        const flags =
          BenefitContentFlags.get(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need to cast here
            context.parent.benefitType as BenefitTypeBenEx,
          ) ?? [];

        for (const flag of value?.values() ?? []) {
          if (!flags.includes(flag)) return false;
        }

        return true;
      },
    }),
  benefitLevel: benefitLevelsValidationSchema.nullable().test({
    name: "benefit-levels-validation",
    test: (val, context) => {
      let isValid = true;
      if (val) {
        const availableLevels =
          BenefitLevelsByBenefit.get(
            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- we need to cast here
            context.parent.benefitType as BenefitTypeBenEx,
          ) ?? [];
        isValid = availableLevels.includes(val);
      }
      return isValid;
    },
  }),
  wellnessAmount: Yup.number().optional().nullable(),
  prepaidSitusState: locationStateCodeValidation.nullable().when(["planType", "carrierId"], {
    is: (val: PlanType, carrierId: number) => {
      if (val === "DHMO" && carrierId === 1) {
        const statesByDentalPlan = DentalStateByPlanType.get(val) ?? [];
        if (statesByDentalPlan.length === 0) return false;
        return true;
      }
      return false;
    },
    then: (schema) => schema.required(),
  }),
  prepaidDentalNetwork: dentalNetworksValidationSchema
    .nullable()
    .when(["benefitType", "carrierId"], {
      is: (benefitType: BenefitTypeBenEx, carrierId: number) => {
        return benefitType === "DENTAL" && carrierId === 1;
      },
      then: (schema) => schema.required(),
    }),
  premium: Yup.string()
    .nullable()
    .when(["benefitType", "hidePremiumOutOfPocket"], {
      is: (benefitType: BenefitTypeBenEx, hidePremiumOutOfPocket = true) =>
        benefitType === "MEDICAL" && !hidePremiumOutOfPocket,
      then: (schema) => schema.trim().nullable().required("Please select a Premium amount"),
      otherwise: (schema) => schema.trim().optional().nullable(),
    }),
  outOfPocketCosts: Yup.string()
    .nullable()
    .when(["benefitType", "hidePremiumOutOfPocket"], {
      is: (benefitType: BenefitTypeBenEx, hidePremiumOutOfPocket = true) =>
        benefitType === "MEDICAL" && !hidePremiumOutOfPocket,
      then: (schema) => schema.trim().nullable().required("Please select an Out-of-pocket amount"),
      otherwise: (schema) => schema.trim().optional().nullable(),
    }),
  hidePremiumOutOfPocket: Yup.boolean().nullable(),
  spanishPlanSummaryDocumentId: Yup.string().optional().nullable(),
  linkedHDHPPlanName: Yup.string().nullable(),
})
  .concat(benefitPlanComparisonTableFields)
  .concat(medicalPlanComparisonTableFields)
  .concat(dentalPlanComparisonTableFields)
  .concat(visionPlanComparisonTableFields)
  .concat(fsaRolloverFields);
