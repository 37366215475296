import type { Client } from "shared/types/Client";

// https://www.npmjs.com/package/accesscontrol#resources-and-resource-attributes
const getRoleDeniedProperties = (permissionProperties: Record<string, boolean>) => {
  return [
    "*",
    ...Object.keys(permissionProperties)
      .filter((k) => permissionProperties[k] === false)
      .map((i) => `!${i}`),
  ];
};

const blockedFields = {
  id: false,
  isActive: false,
  isTest: false,
  isBenefitsExplorer: false,
  isOnboard: false,
  isMaxwell: false,
  isArchived: false,
  implementationType: false,
  inForceSLCoverages: false,
  baseClientId: false,
  name: false,
  caseId: false,
  kickoffCallUpdatedByUser: false,
  installCallUpdatedByUser: false,
  enrollmentNeedsUpdatedByUser: false,
  benAdminPlatformUpdatedByUser: false,
  dataFeedsUpdatedByUser: false,
  setYourGoalsUpdatedByUser: false,
  slfImplementationConsultant: false,
  clientErrors: false,
  authorizedSignerUser: false,
  deifChangesDeclinedByUser: false,
  deifChangesOutsideDeclinedByUser: false,
  deifChangesReadyForReviewByUser: false,
  additionalWebAdminContacts: false,
  deifChangesAcceptedByUser: false,
  deifChangesOutsideAcceptedByUser: false,
};

const brokerAndBAClientUpdateFields: Record<keyof Client, boolean> = {
  ...blockedFields,

  // relationships
  policies: false,
  users: false,
  tasks: false,
  plans: false,
  bills: false,
  deletedBills: false,
  subsidiaries: false,
  contacts: false,
  apiJobs: false,

  ticketId: false,
  limitedEmails: false,
  webAdminContact: true,
  allPoliciesSlfCoverages: true,
  allPoliciesSlfCoveragesReady: true,
  employeesNumber: false,
  mvpBrokerTier: false,
  eoiRequired: false,
  sLConnectCredentialSentDate: false,
  baRegisteredSLConnect: false,
  creCssEmailAddress: false,
  creCssName: false,
  territory: false,
  completedSetup: false,
  completedSetupAt: false,
  priorCarrierCertificationsStatus: false,
  NIGOReasons: false,
  jiraUpdatedAt: false,
  releaseStatus: false,
  postOnboardingSurveySent: false,
  VDRReviewed: true,
  kickoffCallSelection: true,
  kickoffCallSchedule: true,
  kickoffCallStatus: false,
  kickoffCallTopics: true,
  kickoffCallPeople: true,
  kickoffCallUpdatedAt: false,
  kickoffCallUpdatedBy: false,
  installCallSelection: true,
  installCallSchedule: true,
  installCallTopics: true,
  installCallPeople: true,
  installCallUpdatedAt: false,
  installCallUpdatedBy: false,
  enrollmentNeeds: true,
  enrollmentNeedsUpdatedAt: false,
  upcomingEnrollment: true,
  firstPolicyEffective: false,
  enrollmentDateStart: true,
  enrollmentDateEnd: true,
  enrollmentEventUpdatedByUser: true,
  dontKnowEnrollmentEventDates: true,
  enrollmentEventUpdatedAt: true,
  enrollmentEventUpdatedBy: false,
  enrollmentSupport: true,
  streamlinedTechnology: true,
  valueAddedServices: true,
  billing: true,
  ongoingService: true,
  additionalSupport: true,
  otherTopicsToPayAttentionTo: true,
  otherTopicsToPayAttentionToText: true,
  productVariety: true,
  recommendationFromBroker: true,
  qualityCoverage: true,
  digitalCapabilities: true,
  service: true,
  cost: true,
  claimsManagement: true,
  mostImportantOther: true,
  mostImportantOtherText: true,
  setYourGoalsUpdatedAt: false,
  setYourGoalsUpdatedBy: false,
  slfImplementationConsultantId: false,
  ebrEmailAddress: false,
  issuedStateSitus: false,
  riskAcceptanceEmailSentDate: false,
  genericDentalIdCardEmailSentDate: false,
  personalizedDentalIdCardEmailSentDate: false,
  createdAt: false,
  updatedAt: false,
  deletedAt: false,
  createdBy: false,
  updatedBy: false,
  deletedBy: false,
  liveReleaseStatusAt: false,
  digitalEIF: false,
  pushedToCompassAt: false,
  needsEmployeeClasses: true,
  didPrefillCompanyInformation: true,
  prefillCompanyInformation: true,
  alwaysShowBenefitSummaries: true,
  completedPaperworkReceivedOutsideOnboard: false,
  censusTaskHidden: false,
  ageRules: false,
  benefitsExplorerCustomBenefitOrder: false,
  benefitsExplorerCustomBranding: false,
  nonSunLifeBenefitsExplorer: false,
  webAdminContactId: true,
  limitDueDateEmails: false,
  taxId: true,
  previouslyInsuredBySunLife: true,
  previousSlfPolicies: true,
  allowClientSelfServicePlanConfig: false,
  allowClientToViewPlanConfig: false,
  authorizedSignerUserId: false,
  brokerAsAuthorizedSigner: false,
  outsideSigner: false,
  monthlyClaimsReportMailingLocations: false,
  benefitsReplacedWithSlf: true,
  disabilityClaimsPreferencesDefaults: true,
  policyAnniversaryMonth: true,
  unionMembersCovered: true,
  domesticPartnersCovered: true,
  timeSpentShouldCountTowardsWaitingPeriod: true,
  timeSpentAsPartTimeShouldCount: true,
  timeSpentAsOtherShouldCount: true,
  timeSpentAsOtherShouldCountText: true,
  waitingPeriodShouldBeWaived: true,
  shouldCertificatesBeSplitInAnotherWay: true,
  shouldCertificatesBeSplitInAnotherWayText: true,
  reHireProvisionExists: true,
  reHireProvisionUnit: true,
  reHireProvisionDuration: true,
  rehireProvision: true,
  hasSection125Plan: true,
  hasSection125Dental: true,
  hasSection125Vision: true,
  hasSection125DHMO: true,
  hasAdditionalCoverageIncludedIn125Plan: true,
  additonalCoverageIncludedInSection125Plan: true,
  ageReductionDecreaseTakeEffectWhen: true,
  ageBandIncreaseTakeEffectWhen: true,
  ageForDependentLifeBenefits: true,
  changesForDependentLifeBenefitsUseAge: true,
  otherCoverageChangesTakeEffectWhen: true,
  firstOfTheFollowingMonthIncludesFirstDay: true,
  whenDoYouAdministerYourAEP: true,
  whenDoYouAdministerYourAEPStartDate: true,
  whenDoYouAdministerYourAEPEndDate: true,
  whenDoAEPTakeEffect: true,
  whenDoAEPTakeEffectOther: true,
  whenDecreasesInsuranceTakeEffect: true,
  changesOnlyAllowedDuringAE: true,
  whenDoChangesToBenefitsTakeEffect: true,
  erisaHasPlan: true,
  erisaPlanType: true,
  erisaPlanTypeOther: true,
  erisaPrefersPlanDetails: true,
  erisaPlanAdminName: true,
  erisaStreetAddress: true,
  erisaCity: true,
  erisaState: true,
  erisaZipCode: true,
  erisaPlanNumber: true,
  erisaPlanNumberOther: true,
  erisaPlanYearEndDate_deprecated: true,
  erisaPlanYearEndDateMMDD: true,
  erisaAdministerOwnPlan: true,
  erisaHasAgentForLegalProcess: true,
  erisaIsLegalAgentSameAsAdmin: true,
  erisaLegalAgentCity: true,
  erisaLegalAgentName: true,
  erisaLegalAgentState: true,
  erisaLegalAgentStreetAddress: true,
  erisaLegalAgentZipCode: true,
  valueAddedServicesOptions: true,
  valueAddedServicesStepViewed: true,
  eifSignedAt: true,
  eifSignedBy: true,
  eifSignedByUser: true,
  eifOutsideSignedBy: true,
  eifOutsideSignedByUser: true,
  eifBAReviewAndSubmitEmailSentDate: false,
  deifChangesAcceptedAt: true,
  deifChangesAcceptedBy: false,
  deifChangesOutsideAcceptedBy: false,
  deifChangesDeclinedAt: true,
  deifChangesOutsideDeclinedBy: false,
  deifChangesDeclinedBy: false,
  deifChangesDeclinedReason: true,
  deifChangesReadyForReviewAt: true,
  deifChangesReadyForReviewBy: false,
  allEmployeesActivelyAtWork: true,
  allBenefitsNonContributory: false,
  monthlyClaimsReportsAndEOBsDefaults: true,
  claimsCheckPreferencesDefaults: true,
  claimsCheckStepViewed: true,
  healthcareProfessionalsSegment: false,
  malpracticeReimbursementRider: true,
  malpracticeReimbursementPayableTo: true,
  pfmlPreferencesStepViewed: true,
  fliPreferencesStepViewed: true,
  fliCoversAllEmployees: true,
  fliExcludedGroups: true,
  activelyAtWorkUpdatedAt: false,
  activelyAtWorkUpdatedBy: false,
  criticalIllnessType: true,
  criticalIllnessRateType: true,
  criticalIllnessDependentAge: true,
  criticalIllnessDependentTobacco: true,
  hasSubsidiary: true,
  subsidiaryEEsInNYNJOrHI: true,
  mainPolicyHolderEEsInNYNJOrHI: true,
  mainPolicyHolderEEStates: true,
  deductibleMaximumReportTask: true,
};

// @todo: update and use field permission for internal users (check extra fields)
const _internalUserClientUpdateFields: Record<keyof Client, boolean> = {
  ...brokerAndBAClientUpdateFields,
  completedSetup: true,
  limitDueDateEmails: true,
  alwaysShowBenefitSummaries: true,
  eifSignedAt: false,
};

export const deniedBrokerAndBAClientProperties = getRoleDeniedProperties(
  brokerAndBAClientUpdateFields,
);
