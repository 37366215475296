import { useQueryClient } from "@tanstack/react-query";

import { compareQueryId, useSlobMutation, useSlobQuery } from "client/src/hooks/query";
import { blobDocumentToObjectUrl, useDownloadDocument } from "client/src/hooks/utils";
import { useCallback, useEffect, useState } from "react";

import type { QueryClient } from "@tanstack/react-query";
import type { GenericAbortSignal } from "axios";
import type { JsonToTypeMapper } from "client/src/hooks/query";
import type { CensusDomainValidations, ValidateAndSubmitResponse } from "shared/types/Census";
import type { ClientId, PolicyId } from "shared/types/Client";
import type {
  Document,
  CensusDocument,
  DocumentCategory,
  DocumentDownloadUrl,
  DocumentLanguage,
  NewDocumentNotice,
  DocumentId,
} from "shared/types/Document";
import type { UserId } from "shared/types/User";
import type { VidyardDocument } from "shared/types/VidyardDocument";
import type { DocumentMeta } from "shared/validation/document";

export const jsonCensusDocumentToCensusDocument: JsonToTypeMapper<CensusDocument> = (document) => {
  return {
    ...document,
    createdAt: new Date(document.createdAt),
    updatedAt: new Date(document.updatedAt),
    deletedAt: document.deletedAt ? new Date(document.deletedAt) : null,
  };
};

const jsonDocumentToDocument: JsonToTypeMapper<Document> = (document) => {
  return {
    ...document,
    createdAt: new Date(document.createdAt),
    updatedAt: new Date(document.updatedAt),
    deletedAt: document.deletedAt ? new Date(document.deletedAt) : null,
  };
};

const jsonNewDocumentNoticeToNewDocumentNotice: JsonToTypeMapper<NewDocumentNotice> = (
  newDocumentNotice,
) => {
  return {
    ...newDocumentNotice,
    document: jsonDocumentToDocument(newDocumentNotice.document),
    createdAt: new Date(newDocumentNotice.createdAt),
    updatedAt: new Date(newDocumentNotice.updatedAt),
    deletedAt: newDocumentNotice.deletedAt ? new Date(newDocumentNotice.deletedAt) : null,
  };
};

export const useGetDocumentDownloadUrl = (
  clientId: ClientId,
  documentId: number | string | undefined | null,
  signal?: GenericAbortSignal | undefined,
) =>
  useSlobQuery<DocumentDownloadUrl>({
    method: "get",
    path: `/api/clients/${clientId}/documents/${String(documentId)}`,
    requestConfig: { responseType: "blob", signal },
    mapBlob: blobDocumentToObjectUrl,
    options: {
      enabled: Boolean(documentId),
      gcTime: 0,
    },
  });

export const useGetPublicDocumentDownloadUrl = (documentId: number | string | undefined) =>
  useSlobQuery<DocumentDownloadUrl>({
    method: "get",
    path: `/api/documents/${String(documentId)}`,
    requestConfig: { responseType: "blob" },
    mapBlob: blobDocumentToObjectUrl,
    options: {
      enabled: Boolean(documentId),
      gcTime: 0,
    },
  });

export const getDocumentsParams = ({
  categories,
  policyId,
  language,
  documentId,
  documentIds,
}: {
  categories: DocumentCategory[];
  policyId?: string;
  language?: DocumentLanguage;
  documentId?: DocumentId;
  documentIds?: DocumentId[];
}) => {
  const sortedCategories = categories.sort();
  const params = new URLSearchParams();
  for (const category of sortedCategories) {
    params.append("categories[]", category);
  }
  if (policyId) {
    params.append("policyId", policyId);
  }
  if (documentId) {
    params.append("documentId", documentId);
  }
  const sortedDocumentIds = documentIds?.sort() ?? [];
  for (const documentId of sortedDocumentIds) {
    params.append("documentIds[]", documentId);
  }
  if (language) params.append("language", language);
  const qs = params.toString();
  return qs;
};

type GetDocumentsArgs = {
  clientId: ClientId;
  categories: DocumentCategory[];
  policyId?: string;
  language?: DocumentLanguage;
  documentId?: DocumentId;
  documentIds?: DocumentId[];
};

export const useGetDocuments = ({
  clientId,
  categories,
  policyId,
  language,
  documentId,
  documentIds,
  enabled = true,
}: GetDocumentsArgs & { enabled?: boolean }) => {
  const qs = getDocumentsParams({
    categories,
    policyId,
    language,
    documentId,
    documentIds,
  });

  return useSlobQuery<CensusDocument[]>({
    method: "get",
    path: `/api/clients/${clientId}/documents/meta?${qs}`,
    map: (docs) => docs.map((d) => jsonCensusDocumentToCensusDocument(d)),
    options: {
      enabled: !!clientId && enabled,
    },
  });
};

const getDocumentCountParams = (categories: DocumentCategory[], policyId?: string) => {
  const params = new URLSearchParams();
  for (const category of categories) {
    params.append("categories[]", category);
  }
  if (policyId) {
    params.append("policyId", policyId);
  }
  const qs = params.toString();

  return qs;
};

export const useGetDocumentCount = (
  clientId: ClientId,
  categories: DocumentCategory[],
  policyId?: string,
) => {
  const qs = getDocumentCountParams(categories, policyId);
  return useSlobQuery<{ count: number }>({
    method: "get",
    path: `/api/clients/${clientId}/documents/count?${qs}`,
    map: (d) => d,
    options: {
      enabled: !!clientId,
    },
  });
};

export function useGetDocumentsForUser(
  clientId: ClientId,
  category: DocumentCategory,
  userId: UserId,
  policyId?: string,
) {
  const { isFetching, error, refetch, data } = useGetDocuments({
    clientId,
    categories: [category],
    policyId,
  });

  const filterDocuments = (documents: Document[] | undefined) =>
    documents?.filter((d: Document) => d.updatedBy === userId);

  const refetchForUser = async () => {
    const { data } = await refetch();
    return filterDocuments(data);
  };

  return {
    isFetching,
    error,
    refetch: refetchForUser,
    data: filterDocuments(data),
  };
}

export const useGetLastTouchedDocument = (
  clientId: ClientId,
  category: DocumentCategory,
  policyId?: string,
) =>
  useSlobQuery<Document | "">({
    method: "get",
    queryId: "get-last-touched",
    path: `/api/clients/${String(clientId)}/documents/last-touched/${category}${
      policyId ? `?policyId=${policyId}` : ""
    }`,
    map: (d) => (d ? jsonDocumentToDocument(d) : ""),
  });

export const useUploadDocument = (policyId?: string) => {
  const queryClient = useQueryClient();
  return useSlobMutation<FormData, Document, `/api/clients/:clientId/documents`>({
    method: "post",
    path: `/api/clients/:clientId/documents`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    map: jsonDocumentToDocument,
    options: {
      async onSuccess(response, { params: { clientId } }) {
        const { category } = response.data;

        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryId("get-last-touched"),
          }),
          queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}`] }),
          queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}/setup`] }),
          invalidateGetDocumentsQueries(queryClient, clientId, category),
          queryClient.invalidateQueries({
            queryKey: ["get", `/api/clients/${clientId}/zipped-documents/ids`],
          }),
          queryClient.invalidateQueries({
            queryKey: ["get", `/api/clients/${clientId}/new-document-notice`],
          }),
          invalidateDocumentCountQuery(queryClient, clientId, category, policyId),
        ]);
      },
    },
  });
};

export const useDeleteDocument = (
  category: DocumentCategory | undefined | null,
  policyId?: string,
) => {
  const queryClient = useQueryClient();
  return useSlobMutation({
    method: "delete",
    path: `api/clients/:clientId/documents/:documentId`,
    options: {
      async onSuccess(_, { params: { clientId } }) {
        const cacheInvalidations = [
          queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}`] }),
        ];
        if (category) {
          cacheInvalidations.push(
            queryClient.invalidateQueries({
              predicate: compareQueryId("get-last-touched"),
            }),
            queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}/setup`] }),
            invalidateDocumentCountQuery(queryClient, clientId, category, policyId),
            invalidateGetDocumentsQueries(queryClient, clientId, category),
          );
        }
        await Promise.all(cacheInvalidations);
      },
    },
  });
};

export const useRenameDocument = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<
    { name: string },
    Document,
    `api/clients/:clientId/documents/:documentId/rename`
  >({
    method: "put",
    path: `api/clients/:clientId/documents/:documentId/rename`,
    map: jsonDocumentToDocument,
    options: {
      async onSuccess(response, { params: { clientId } }) {
        const category = response.data.category;

        const cacheInvalidations = [
          queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}`] }),
          queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}/setup`] }),
          queryClient.invalidateQueries({
            predicate: compareQueryId("get-last-touched"),
          }),
          queryClient.invalidateQueries({
            queryKey: ["get", `/api/clients/${clientId}/zipped-documents/ids`],
          }),
          invalidateGetDocumentsQueries(queryClient, clientId, category),
        ];
        await Promise.all(cacheInvalidations);
      },
    },
  });
};

export const useUpdateDocumentMeta = () => {
  const queryClient = useQueryClient();
  return useSlobMutation<DocumentMeta, boolean, `api/clients/:clientId/documents/meta`>({
    method: "put",
    path: `api/clients/:clientId/documents/meta`,
    options: {
      async onSuccess(_response, { params: { clientId } }) {
        const cacheInvalidations = [
          queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}`] }),
          queryClient.invalidateQueries({
            predicate: compareQueryId("get-last-touched"),
          }),
          invalidateGetDocumentsQueries(queryClient, clientId, "enrollment-elections"),
        ];
        await Promise.all(cacheInvalidations);
      },
    },
  });
};

export const useSubmitCensus = () => {
  const queryClient = useQueryClient();

  return useSlobMutation({
    method: "post",
    path: `/api/clients/:clientId/census/:policyId/submit`,
    options: {
      async onSuccess(_response, { params: { clientId } }) {
        const cacheInvalidations = [
          queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}`] }),
          invalidateGetDocumentsQueries(queryClient, clientId, "enrollment-elections"),
        ];
        await Promise.all(cacheInvalidations);
      },
    },
  });
};
const jsonValidationsToValidations: JsonToTypeMapper<CensusDomainValidations> = (
  censusValidations,
) => {
  return censusValidations;
};

const jsonValidateSubmitToValidateSubmit: JsonToTypeMapper<ValidateAndSubmitResponse> = (data) => {
  return {
    document: jsonCensusDocumentToCensusDocument(data.document),
    submitted: data.submitted,
    domainValidations: data.domainValidations,
  };
};

export function useCensusValidateSubmit(clientId: string, policyId: PolicyId) {
  const queryClient = useQueryClient();
  const [result, setResult] = useState<ValidateAndSubmitResponse[]>();

  const {
    mutateAsync: censusValidateSubmit,
    isPending,
    error,
  } = useSlobMutation<null, ValidateAndSubmitResponse[]>({
    method: "put",
    path: `/api/clients/${clientId}/census/${policyId}/validate-submit`,
    map: (data) => data.map(jsonValidateSubmitToValidateSubmit),
    options: {
      async onSuccess(response) {
        if (response.data.some((data) => data.document.processStatus)) {
          const cacheInvalidations = [
            queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}`] }),
            queryClient.invalidateQueries({ queryKey: ["get", `/api/clients/${clientId}/setup`] }),
            queryClient.invalidateQueries({
              predicate: compareQueryId("get-last-touched"),
            }),
            queryClient.invalidateQueries({
              queryKey: ["get", `/api/clients/${clientId}/zipped-documents/ids`],
            }),
            invalidateGetDocumentsQueries(queryClient, clientId, "enrollment-elections"),
            invalidateDocumentCountQuery(queryClient, clientId, "enrollment-elections", policyId),
          ];
          await Promise.all(cacheInvalidations);
        }
      },
    },
  });

  const getData = useCallback(async () => {
    const { data } = await censusValidateSubmit({ data: null });
    setResult(data);
  }, [censusValidateSubmit]);

  useEffect(() => {
    void getData();
  }, [getData]);

  return { data: result, isPending, error, validateSubmitCensus: getData };
}

// Used in reports tab
export const useGetCensusValidations = (
  clientId: ClientId | undefined,
  documentId: DocumentId | undefined,
) =>
  useSlobQuery<CensusDomainValidations>({
    method: "get",
    path: `/api/clients/${clientId}/census/${documentId}/validate`,
    map: jsonValidationsToValidations,
    options: { enabled: Boolean(clientId && documentId) },
  });

export type UpdateDocumentMetaQuery = Pick<
  ReturnType<typeof useUpdateDocumentMeta>,
  "isPending" | "mutateAsync"
>;

/**
 * Returns a collection of documents for a client and category,
 * plus a function to download a document from that list by the document id.
 * Also returns a loading boolean and any error encountered in the process
 */
export function useDocumentDownloadHelper(
  clientId: ClientId,
  categories: DocumentCategory[],
  policyId?: string,
  enabled?: boolean,
) {
  const {
    isLoading: isLoadingDocuments,
    isFetching: isFetchingDocuments,
    error: errorGetDocuments,
    data: documents,
  } = useGetDocuments({ clientId, categories, policyId, enabled });

  const { isDownloading, isFetchingDocumentURL, errorDocumentURL, downloadDocument } =
    useDocumentDownloadHelperById(clientId);

  return {
    isLoadingDocuments,
    isFetchingDocuments,
    isDownloading,
    isFetchingDocumentURL,
    errorGetDocuments,
    errorDocumentURL,
    documents,
    downloadDocument,
  };
}

export function useDocumentDownloadHelperById(
  clientId: ClientId,
  signal?: GenericAbortSignal | undefined,
) {
  const [documentId, setDocumentId] = useState<number | string | undefined>();
  const {
    isLoading: isDownloading,
    isFetching: isFetchingDocumentURL,
    error: errorDocumentURL,
    data: documentURL,
    refetch: refetchDocumentUrl,
  } = useGetDocumentDownloadUrl(clientId, documentId, signal);

  useDownloadDocument(documentURL);

  return {
    isDownloading,
    isFetchingDocumentURL,
    errorDocumentURL,
    downloadDocument: (id: number | string | undefined) =>
      id === documentId ? void refetchDocumentUrl() : setDocumentId(id),
  };
}

export function useGetNewDocumentNotices(clientId: ClientId) {
  return useSlobQuery<NewDocumentNotice[]>({
    method: "get",
    path: `/api/clients/${String(clientId)}/new-document-notice`,
    map: (d) => d.map(jsonNewDocumentNoticeToNewDocumentNotice),
  });
}

export function useMarkNewDocumentNoticesAsSeen(clientId: ClientId) {
  const { mutateAsync: markNewDocumentNoticeAsSeen } = useSlobMutation<{
    newDocumentNoticeStatus: "NEW_AND_SEEN";
  }>({
    method: "put",
    path: `/api/clients/${clientId}/new-document-notice`,
  });

  useEffect(() => {
    void markNewDocumentNoticeAsSeen({
      data: { newDocumentNoticeStatus: "NEW_AND_SEEN" as const },
    });
  }, [markNewDocumentNoticeAsSeen]);
}

export function useMarkNewSeenDocumentNoticesAsOld() {
  const { mutateAsync, ...rest } = useSlobMutation<{
    newDocumentNoticeStatus: "OLD";
  }>({
    method: "put",
    path: `/api/users/new-document-notice`,
  });

  const markNewDocumentNoticesAsOld = useCallback(() => {
    return mutateAsync({
      data: {
        newDocumentNoticeStatus: "OLD",
      },
    });
  }, [mutateAsync]);

  return {
    ...rest,
    markNewDocumentNoticesAsOld,
  };
}

export type AsyncUploadDocumentToVidyard = ReturnType<
  typeof useUploadDocumentToVidyard
>["mutateAsync"];

export const useUploadDocumentToVidyard = () => {
  return useSlobMutation<
    { docId: string; docExt: string; videoTitle: string },
    VidyardDocument,
    `/api/clients/:clientId/documents/vidyard/upload`
  >({
    method: "post",
    path: `/api/clients/:clientId/documents/vidyard/upload`,
  });
};

export const useDeleteDocumentFromVidyard = () => {
  return useSlobMutation<
    undefined,
    undefined,
    `/api/clients/:clientId/documents/vidyard/delete/:vidyardId`
  >({
    method: "delete",
    path: `/api/clients/:clientId/documents/vidyard/delete/:vidyardId`,
  });
};

function invalidateDocumentCountQuery(
  queryClient: QueryClient,
  clientId: string | number,
  category: string,
  policyId: string | undefined,
) {
  return queryClient.invalidateQueries({
    predicate: (query) => {
      if (Array.isArray(query.queryKey)) {
        const [method, path] = query.queryKey;
        if (typeof path === "string") {
          const [basePath, qs] = path.split("?");
          const searchParams = new URLSearchParams(qs);
          const categories = searchParams.getAll("categories[]");
          const paramPolicyId = searchParams.get("policyId");
          const shouldInvalidate =
            method === "get" &&
            basePath === `/api/clients/${clientId}/documents/count` &&
            (policyId == null || policyId === paramPolicyId) &&
            categories.includes(category);
          return shouldInvalidate;
        }
      }
      return false;
    },
  });
}

function invalidateGetDocumentsQueries(
  queryClient: QueryClient,
  clientId: string | number,
  category: DocumentCategory,
) {
  return queryClient.invalidateQueries({
    predicate: (query) => {
      if (Array.isArray(query.queryKey)) {
        const [method, path] = query.queryKey;
        if (typeof path === "string") {
          const [basePath, qs] = path.split("?");
          const searchParams = new URLSearchParams(qs);
          const categories = searchParams.getAll("categories[]");
          const shouldInvalidate =
            method === "get" &&
            basePath === `/api/clients/${clientId}/documents/meta` &&
            categories.includes(category);
          return shouldInvalidate;
        }
      }
      return false;
    },
  });
}
