import { Portal } from "@reach/portal";
import * as Sentry from "@sentry/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { AppRouter } from "client/src/Router";
import { LoadingError } from "client/src/components/Error/LoadingError";
import { Loading } from "client/src/components/Loading/Loading";
import { ProgressOverlayProvider } from "client/src/domain/EIF/QPSPushProgressOverlay/QPSPushProgressOverlay";
import { getQueryClient } from "client/src/hooks/query";
import { ConfigurationProvider } from "client/src/hooks/useConfig";
import { LocalStorageProvider } from "client/src/hooks/useSlobLocalStorage";
import { Settings } from "luxon";
import React from "react";

import "./app.less";

Settings.defaultLocale = "en";

const queryClient = getQueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Sentry.ErrorBoundary fallback={Fallback}>
        <ConfigurationProvider>
          <LocalStorageProvider>
            <ProgressOverlayProvider>
              <React.Suspense fallback={<Loading />}>
                <AppRouter />
              </React.Suspense>
              <React.Suspense fallback={null}>
                <Portal type="react-query-devtools-portal">
                  <div className="ReactQueryDevtools_toggleButton">
                    <ReactQueryDevtools buttonPosition="bottom-left" />
                  </div>
                </Portal>
              </React.Suspense>
            </ProgressOverlayProvider>
          </LocalStorageProvider>
        </ConfigurationProvider>
      </Sentry.ErrorBoundary>
    </QueryClientProvider>
  );
};

const Fallback: Sentry.FallbackRender = (props) => {
  const { error } = props;
  return (
    <>
      {/* This empty div is necessary so that the LoadingError vertically fills the page */}
      <div />
      <LoadingError type="page" error={error} />
    </>
  );
};
