import { keepPreviousData, type QueryClient } from "@tanstack/react-query";
import { compareQueryKey, useSlobQuery } from "client/src/hooks/query";
import { useMemo } from "react";
import { getDEIFChangeSnapshot } from "shared/utils/EIF/changeLog";
import type { JsonToTypeMapper } from "client/src/hooks/query";
import type { ChangeWithDetails, DEIFChangeSnapshot } from "shared/types/Change";
import type { ClientId } from "shared/types/Client";

export const jsonChangeToChange: JsonToTypeMapper<ChangeWithDetails> = (changeLog) => ({
  ...changeLog,
  createdAt: new Date(changeLog.createdAt),
  changeDetails: changeLog.changeDetails.map((changeDetail) => ({
    ...changeDetail,
    createdAt: new Date(changeDetail.createdAt),
  })),
});

export const useGetClientDEIFChanges = (
  clientId: ClientId,
  { enabled }: { enabled: boolean } = { enabled: true },
) => {
  const path = `/api/clients/${clientId}/change-log/deif-changes`;
  const query = useSlobQuery<ChangeWithDetails[]>({
    method: "get",
    path,
    map: (c) => c.map(jsonChangeToChange),
    options: {
      placeholderData: keepPreviousData,
      enabled,
    },
  });

  return query;
};

export async function invalidateDEIFChanges(queryClient: QueryClient, clientId: ClientId) {
  await queryClient.invalidateQueries({
    predicate: compareQueryKey(["get", `/api/clients/${clientId}/change-log/deif-changes`]),
  });
}

export function useChangeSnapshot(changeLogs: ChangeWithDetails[]): DEIFChangeSnapshot;
export function useChangeSnapshot(changeLogs: undefined): null;
export function useChangeSnapshot(
  changeLogs: ChangeWithDetails[] | undefined,
): DEIFChangeSnapshot | null;
export function useChangeSnapshot(changeLogs: ChangeWithDetails[] | undefined) {
  const changeSnapshot = useMemo(() => {
    if (!changeLogs) {
      return null;
    }

    const changeSnapshot = getDEIFChangeSnapshot(changeLogs);
    return changeSnapshot;
  }, [changeLogs]);

  return changeSnapshot;
}
