import { useQueryClient } from "@tanstack/react-query";
import { invalidateDEIFChanges } from "client/src/hooks/changeLogs";
import { jsonPlanToPlan } from "client/src/hooks/plans";
import { compareQueryKey, useSlobMutation } from "client/src/hooks/query";
import type { JsonToTypeMapper, ResponseError } from "client/src/hooks/query";
import type {
  EmployeeClassPlanWithPlan,
  SetBenefitTypeAvailablePlansInput,
  SetBenefitTypeAvailablePlansOutput,
  SetContributionsInput,
  SetContributionsOutput,
  setWaitingPeriodInput,
  SetWaitingPeriodOutput,
} from "shared/types/EmployeeClassPlan";

export const jsonClassPlanToClassPlan: JsonToTypeMapper<EmployeeClassPlanWithPlan> = (
  employeeClassPlan,
) => {
  return {
    ...employeeClassPlan,
    plan: jsonPlanToPlan(employeeClassPlan.plan),
    createdAt: new Date(employeeClassPlan.createdAt),
    updatedAt: new Date(employeeClassPlan.updatedAt),
    deletedAt: employeeClassPlan.deletedAt ? new Date(employeeClassPlan.deletedAt) : null,
  };
};

export type SetBenefitTypeAvailablePlansQuery = Pick<
  ReturnType<typeof useSetBenefitTypeAvailablePlans>,
  "isPending" | "mutateAsync"
>;

export function useSetBenefitTypeAvailablePlans() {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    SetBenefitTypeAvailablePlansInput,
    SetBenefitTypeAvailablePlansOutput,
    `/api/clients/:clientId/employee-classes/:employeeClassId/plans/set-benefit-type-plans`
  >({
    method: "post",
    path: `/api/clients/:clientId/employee-classes/:employeeClassId/plans/set-benefit-type-plans`,
    options: {
      async onSuccess({ data: { clientId, employeeClassId, employeeClass } }) {
        queryClient.setQueryData(
          ["get", `/api/clients/${clientId}/employee-classes/${employeeClass.id}`],
          employeeClass,
        );

        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/employee-classes`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey([
              "get",
              `/api/clients/${clientId}/employee-classes/${employeeClassId}/plans`,
            ]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });

  return query;
}

export type SetWaitingPeriodsQuery = Pick<
  ReturnType<typeof useSetWaitingPeriods>,
  "isPending" | "mutateAsync"
>;

export const useSetWaitingPeriods = () => {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    setWaitingPeriodInput,
    SetWaitingPeriodOutput,
    `/api/clients/:clientId/employee-classes/:employeeClassId/plans/waiting-periods`
  >({
    method: "post",
    path: `/api/clients/:clientId/employee-classes/:employeeClassId/plans/waiting-periods`,
    options: {
      async onSuccess({ data: { clientId, employeeClassId, employeeClass } }) {
        queryClient.setQueryData(
          ["get", `/api/clients/${clientId}/employee-classes/${employeeClassId}`],
          employeeClass,
        );

        await Promise.all([
          queryClient.invalidateQueries({
            predicate: compareQueryKey(["get", `/api/clients/${clientId}/employee-classes`]),
          }),
          queryClient.invalidateQueries({
            predicate: compareQueryKey([
              "get",
              `/api/clients/${clientId}/employee-classes/${employeeClassId}/plans`,
            ]),
          }),
          invalidateDEIFChanges(queryClient, clientId.toString()),
        ]);
      },
    },
  });

  return query;
};

export type SetContributionQuery = Pick<
  ReturnType<typeof useSetContributionsQuery>,
  "isPending" | "isError" | "error" | "mutateAsync"
>;

export const useSetContributionsQuery = () => {
  const queryClient = useQueryClient();

  const query = useSlobMutation<
    SetContributionsInput,
    SetContributionsOutput,
    `/api/clients/:clientId/employee-classes/:employeeClassId/plans/contributions`,
    ResponseError,
    unknown
  >({
    method: "post",
    path: `/api/clients/:clientId/employee-classes/:employeeClassId/plans/contributions`,
    options: {
      async onSuccess({ data: { clientId, employeeClassId, employeeClass } }) {
        queryClient.setQueryData(
          ["get", `/api/clients/${clientId}/employee-classes/${employeeClassId}`],
          employeeClass,
        );
        await queryClient.invalidateQueries({
          predicate: compareQueryKey(["get", `/api/clients/${clientId}/employee-classes`]),
        });
        await queryClient.invalidateQueries({
          predicate: compareQueryKey([
            "get",
            `/api/clients/${clientId}/employee-classes/${employeeClassId}/plans`,
          ]),
        });
        await invalidateDEIFChanges(queryClient, clientId.toString());
      },
    },
  });

  return query;
};
