import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import clsx from "clsx";

import * as styles from "./InputErrorMessage.module.less";

type InputErrorMessageProps = {
  id?: string;
  error?: string;
  disabled?: boolean;
};
export const InputErrorMessage = ({ id, error, disabled }: InputErrorMessageProps) => {
  return (
    <div
      id={id}
      className={clsx(styles.errorContainer, disabled && styles.errorContainer__disabled)}
    >
      <FontAwesomeIcon icon={faExclamationTriangle} /> {error || ""}
    </div>
  );
};
