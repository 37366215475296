import { getIsStatutoryBenefitType } from "shared/types/BenefitTypes";
import { eifStepNavigation, type EIFSubStepId } from "shared/types/EIF";
import {
  getClientCoveragesThatAreAlwaysSentToEmployeesHome,
  getPlansWhereCanSetClaimsCheckLocations,
  type Plan,
} from "shared/types/Plan";
import {
  getIsCoverageSupportedInClassBuilder,
  getIsPFMLCoverage,
  getIsStatutoryCoverage,
} from "shared/types/SlfCoverages";
import { getShouldAskAgeQuestionForSpouseDependentLife } from "shared/utils/EIF/getShouldAskAgeQuestionForSpouseDependentLife";
import { getTerminationOfInsuranceRelevantPlans } from "shared/utils/EIF/getTerminationOfInsuranceRelevantPlans";
import { getPlansWhereCanSetMonthlyClaimsLocations } from "shared/utils/EIF/monthlyClaimsReportsAndEOBs";
import { exhaustiveCheckFail } from "shared/utils/exhaustiveCheck";
import { unique } from "shared/utils/utils";
import type { Client } from "shared/types/Client";
import type { EIFStepId } from "shared/types/EIF";
import type { ClientFeatureToggles } from "shared/types/Toggles";

type Args =
  // Company Details
  | {
      eifSubStepId: Extract<EIFSubStepId, "benefits-administration-and-data-feed">;
      plans: Plan[];
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "tax-id">;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "subsidiaries-and-affiliates">;
      plans: Plan[];
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "current-sun-life-policies">;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "previous-coverage-information">;
      plans: Plan[];
    }
  // Plan Administrators & Billing
  | {
      eifSubStepId: Extract<EIFSubStepId, "plan-administrators">;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "billing-preferences">;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "claims-check-mailing-locations">;
      client: Client;
      plans: Plan[];
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "monthly-claims-reports-and-eobs">;
      plans: Plan[];
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "malpractice-reimbursements">;
      client: Client;
    }
  // Plan Configuration & Eligibility
  | {
      eifSubStepId: Extract<EIFSubStepId, "class-builder">;
      client: Client;
      plans: Plan[];
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "non-class-benefits-preferences">;
      client: Client;
      featureToggles: ClientFeatureToggles;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "fli-preferences">;
      client: Client;
    }
  // Other Contract Details
  | {
      eifSubStepId: Extract<EIFSubStepId, "confirm-policy-anniversary">;
      client: Client;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "additional-waiting-period-rules">;
      client: Client;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "rehire-provision">;
      client: Client;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "union-members-and-domestic-partners">;
      client: Client;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "changes-during-annual-enrollment">;
      client: Client;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "termination-of-insurance">;
      client: Client;
      plans: Plan[];
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "age-rules">;
      client: Client;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "other-changes-to-coverage">;
      client: Client;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "employee-certificate">;
      client: Client;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "section-125">;
      client: Client;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "erisa">;
      client: Client;
    }
  | {
      eifSubStepId: Extract<EIFSubStepId, "value-added-services">;
      client: Client;
    };

export function getIsSubStepApplicable(args: Args) {
  const { eifSubStepId } = args;
  switch (eifSubStepId) {
    // Company Details
    case "tax-id":
    case "current-sun-life-policies":
      return true;

    case "previous-coverage-information":
    case "benefits-administration-and-data-feed": {
      const noPlans = args.plans.length === 0;
      const allPlansAreStatutory = args.plans.every((plan) =>
        getIsStatutoryBenefitType(plan.benefitType),
      );
      return noPlans || !allPlansAreStatutory;
    }

    case "subsidiaries-and-affiliates": {
      const hasStatutoryBenefits = args.plans.some((p) => getIsStatutoryBenefitType(p.benefitType));
      const hasASODental = args.plans.some((p) => p.benefitType === "ASO_DENTAL");
      return hasStatutoryBenefits || hasASODental;
    }

    // Plan Administrators & Billing
    case "plan-administrators":
    case "billing-preferences":
      return true;
    case "claims-check-mailing-locations": {
      const relevantPlans = getPlansWhereCanSetClaimsCheckLocations(args.plans);
      const clientCoveragesThatAreAlwaysSentToEmployeesHome =
        getClientCoveragesThatAreAlwaysSentToEmployeesHome(args.plans);
      const isClaimsCheckPlanSentToEEOnly =
        relevantPlans.length === 0 && clientCoveragesThatAreAlwaysSentToEmployeesHome.length > 0;
      const isApplicable = relevantPlans.length > 0 || isClaimsCheckPlanSentToEEOnly;
      return isApplicable;
    }
    case "monthly-claims-reports-and-eobs": {
      const relevantPlans = getPlansWhereCanSetMonthlyClaimsLocations(args.plans);
      const isApplicable = relevantPlans.length > 0;
      return isApplicable;
    }
    case "malpractice-reimbursements": {
      const isHPS = args.client.healthcareProfessionalsSegment;
      const hasLTD = args.client.allPoliciesSlfCoverages?.some(
        (coverage) => coverage === "Long Term Disability",
      );
      const isApplicable = isHPS && hasLTD;
      return isApplicable;
    }

    // Plan Configuration & Eligibility
    case "class-builder": {
      const clientHasClassBuilderCoverages =
        args.client.allPoliciesSlfCoverages?.some((coverage) =>
          getIsCoverageSupportedInClassBuilder(coverage, args.plans),
        ) ?? false;
      const isApplicable = clientHasClassBuilderCoverages;
      return isApplicable;
    }

    case "non-class-benefits-preferences": {
      const clientHasPFMLCoverages =
        args.client.allPoliciesSlfCoverages?.some((coverage) => getIsPFMLCoverage(coverage)) ??
        false;
      const clientHasStatutoryCoverages =
        args.client.allPoliciesSlfCoverages?.some((coverage) => getIsStatutoryCoverage(coverage)) ??
        false;
      const isApplicable = clientHasPFMLCoverages || clientHasStatutoryCoverages;
      return isApplicable;
    }

    case "fli-preferences": {
      const clientHasFLICoverages =
        args.client.allPoliciesSlfCoverages?.some(
          (coverage) => coverage === "Family Leave Insurance",
        ) ?? false;
      const isApplicable = clientHasFLICoverages;
      return isApplicable;
    }

    // Other Contract Details
    case "confirm-policy-anniversary": {
      const onlyStatutoryCoverages = getOnlyStatutoryCoverages(args);
      if (onlyStatutoryCoverages) return false;

      return true;
    }
    case "additional-waiting-period-rules":
    case "rehire-provision":
    case "union-members-and-domestic-partners":
    case "other-changes-to-coverage":
    case "employee-certificate":
    case "section-125":
    case "erisa": {
      const onlyStatutoryCoverages = getOnlyStatutoryCoverages(args);
      if (onlyStatutoryCoverages) return false;

      const onlyPFMLCoverages = getOnlyPFMLCoverages(args);
      const isApplicable = !onlyPFMLCoverages;
      return isApplicable;
    }
    case "changes-during-annual-enrollment": {
      const onlyStatutoryCoverages = getOnlyStatutoryCoverages(args);
      if (onlyStatutoryCoverages) return false;

      const onlyPFMLCoverages = getOnlyPFMLCoverages(args);
      const isApplicable1 = !onlyPFMLCoverages;
      if (!isApplicable1) return false;

      const slfCoverageHasDental = args.client.allPoliciesSlfCoverages?.includes("Dental");
      const slfCoverageHasVision = args.client.allPoliciesSlfCoverages?.includes("Vision");
      const hasDentalOrVision = slfCoverageHasDental || slfCoverageHasVision;
      const isApplicable2 = args.client.allBenefitsNonContributory !== "YES" || hasDentalOrVision;
      return isApplicable2;
    }
    case "termination-of-insurance": {
      const onlyStatutoryCoverages = getOnlyStatutoryCoverages(args);
      if (onlyStatutoryCoverages) return false;

      const relevantPlans = getTerminationOfInsuranceRelevantPlans(args.plans);
      const benefitTypes = unique(relevantPlans.map((p) => p.benefitType));
      const isApplicable = benefitTypes.length > 0;
      return isApplicable;
    }
    case "age-rules": {
      const onlyStatutoryCoverages = getOnlyStatutoryCoverages(args);
      if (onlyStatutoryCoverages) return false;

      const onlyPFMLCoverages = getOnlyPFMLCoverages(args);
      const isApplicable1 = !onlyPFMLCoverages;
      if (!isApplicable1) return false;

      const isAgeQuestionForSpouseDependentLifeApplicable =
        getShouldAskAgeQuestionForSpouseDependentLife(args.client);

      const isApplicable2 =
        (args.client.ageRules?.length ?? 0) > 0 || isAgeQuestionForSpouseDependentLifeApplicable;
      return isApplicable2;
    }

    case "value-added-services": {
      const onlyStatutoryCoverages = getOnlyStatutoryCoverages(args);
      if (onlyStatutoryCoverages) return false;

      const onlyPFMLCoverages = getOnlyPFMLCoverages(args);
      const isApplicable1 = !onlyPFMLCoverages;
      if (!isApplicable1) return false;

      const showValueAddedServicesLifeCoverage = shouldShowValueAddedServicesLifeCoverage(
        args.client,
      );
      const showValueAddedServicesSuppHealthCoverage =
        shouldShowValueAddedServicesSuppHealthCoverage(args.client);
      const isApplicable2 =
        showValueAddedServicesLifeCoverage || showValueAddedServicesSuppHealthCoverage;
      return isApplicable2;
    }
    default:
      exhaustiveCheckFail(eifSubStepId);
  }
}

// https://maxwellhealth.atlassian.net/browse/TB-5831
function getOnlyPFMLCoverages(args: { client: Client }) {
  const onlyPFMLCoverages =
    args.client.allPoliciesSlfCoverages?.every((coverage) => getIsPFMLCoverage(coverage)) ?? false;
  return onlyPFMLCoverages;
}

// https://maxwellhealth.atlassian.net/browse/TB-6620
function getOnlyStatutoryCoverages(args: { client: Client }) {
  const everyCoverageIsStatutory =
    args.client.allPoliciesSlfCoverages?.every(getIsStatutoryCoverage) ?? false;
  return everyCoverageIsStatutory;
}

export const shouldShowValueAddedServicesLifeCoverage = (client: Client) => {
  const allCoverages = client.allPoliciesSlfCoverages ?? [];
  const hasDisallowedSitusStateNewYork = client.policies.some(
    ({ issuedStateSitus, slfCoverages }) =>
      issuedStateSitus?.includes("NY") &&
      (slfCoverages?.includes("Voluntary Life") || slfCoverages?.includes("Basic Life")),
  );
  const hasVoluntaryOrBasicLifeCoverage = allCoverages.some(
    (coverage) => coverage === "Voluntary Life" || coverage === "Basic Life",
  );

  const showValueAddedServicesLifeCoverage = Boolean(
    !hasDisallowedSitusStateNewYork && hasVoluntaryOrBasicLifeCoverage,
  );

  return showValueAddedServicesLifeCoverage;
};

export const shouldShowValueAddedServicesSuppHealthCoverage = (client: Client) => {
  const allCoverages = client.allPoliciesSlfCoverages ?? [];
  const hasDisallowedSitusStateNewYork = client.policies.some(
    ({ issuedStateSitus, slfCoverages }) =>
      issuedStateSitus?.includes("NY") &&
      (slfCoverages?.includes("Accident") || slfCoverages?.includes("Critical Illness")),
  );
  const hasRelevantSupplementalHealthCoverage = allCoverages.some(
    (coverage) => coverage === "Accident" || coverage === "Critical Illness",
  );

  const showValueAddedServicesSuppHealthCoverage = Boolean(
    !hasDisallowedSitusStateNewYork && hasRelevantSupplementalHealthCoverage,
  );

  return showValueAddedServicesSuppHealthCoverage;
};

export function getAtLeastOneSubStepIsApplicable(
  eifStepId: EIFStepId,
  client: Client,
  plans: Plan[],
  featureToggles: ClientFeatureToggles,
) {
  if (eifStepId === "review-&-submit") return true;

  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
  const subStepsInThisStep = Object.keys(eifStepNavigation[eifStepId]) as EIFSubStepId[];

  const atLeastOneSubStepIsApplicable = subStepsInThisStep.some((eifSubStepId) => {
    const isSubStepApplicable = getIsSubStepApplicable({
      eifSubStepId,
      client,
      plans,
      featureToggles,
    });

    return isSubStepApplicable;
  });
  return atLeastOneSubStepIsApplicable;
}
