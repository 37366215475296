import type { BenefitLevel, DentalNetwork, PlanType } from "shared/types/ExplorerPageBenefit";

export const PlanTypeData: Record<PlanType, { displayName: string; fullName?: string }> = {
  HMO: { displayName: "HMO", fullName: "HMO Plan (Health Maintenance Organization)" },
  PPO: { displayName: "PPO", fullName: "PPO Plan (Preferred Provider Organization)" },
  EPO: { displayName: "EPO", fullName: "EPO Plan (Exclusive Provider Organization)" },
  MEC: { displayName: "MEC", fullName: "MEC Plan (Minimum Essential Coverage)" },
  POS: { displayName: "POS", fullName: "POS Plan (Point of Service)" },
  OAP: { displayName: "OAP", fullName: "OAP (Open Access Plan)" },
  DHMO: { displayName: "DHMO/Prepaid" },
  EMPLOYER_PAID: { displayName: "Employer-paid" },
  VOLUNTARY: { displayName: "Voluntary" },
  BASIC_LIFE: { displayName: "Basic Life" },
  VOLUNTARY_LIFE: { displayName: "Voluntary Life" },
  STANDALONE_ADD: { displayName: "Standalone AD&D" },
  HSA: { displayName: "HSA", fullName: "Health Savings Account (HSA)" },
  HRA: { displayName: "HRA", fullName: "Health Reimbursement Account (HRA)" },
  FSA: { displayName: "FSA", fullName: "Flexible Spending Account (FSA)" },
  LPFSA: { displayName: "LPFSA", fullName: "Limited Purpose Flexible Spending Account (LPFSA)" },
  EAP: { displayName: "EAP" },
  DCA: { displayName: "DCFSA", fullName: "Dependent Care Flexible Spending Account (DCFSA)" },
  PFML_FMLA_LEAVE: { displayName: "PFML/FMLA/Leave" },
  COMMUTER_TRANSIT: { displayName: "Commuter/Transit" },
  DISCOUNT_PROGRAM: { displayName: "Discount Program" },
  FITNESS_PROGRAM: { displayName: "Fitness Program" },
  HEALTH_AND_WELLNESS: { displayName: "Health and Wellness" },
  IDENTITY_PROTECTION: { displayName: "Identity Protection" },
  LEGAL_SERVICES: { displayName: "Legal Services" },
  PET_INSURANCE: { displayName: "Pet Insurance" },
  RETIREMENT: { displayName: "Retirement" },
  TELEHEALTH: { displayName: "Telehealth" },
  TRAVEL_INSURANCE: { displayName: "Travel Insurance" },
  OTHER: { displayName: "Other" },
  ASO_PPO: { displayName: "ASO PPO" },
  INDEMNITY: { displayName: "Indemnity" },
};

export const BenefitLevelData: Record<BenefitLevel, { displayName: string }> = {
  BUY_UP: { displayName: "Buy-up" },
  LOW_HIGH: { displayName: "Low/High" },
  LEVEL_1_LEVEL_2: { displayName: "Level 1/Level 2" },
};

export const DentalNetworkData: Record<DentalNetwork, { displayName: string }> = {
  SUN_LIFE_FOCUS_DENTAL_NETWORK_PPO: { displayName: "Sun Life Focus Dental Network (PPO)" },
  SUN_LIFE_DENTAL_NETWORK_PPO: { displayName: "Sun Life Dental Network (PPO)" },
  HERITAGE: { displayName: "Heritage" },
  DHMO_DENTAL_SERIES: { displayName: "DHMO Dental Series" },
  CA_UDC_PREPAID: { displayName: "CA UDC Prepaid" },
  LEGEND: { displayName: "Legend" },
  UNITED_DENTAL_CARE: { displayName: "United Dental Care" },
  PREPAID_DENTAL_SERIES_FLORIDA_ONLY: { displayName: "Prepaid Dental Series (Florida only)" },
  GEORGIA_PREPAID: { displayName: "Georgia Prepaid" },
};
