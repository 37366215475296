import clsx from "clsx";

import * as styles from "./card.module.less";
import type { JSX } from "react";

type CardProps = {
  children?: React.ReactNode;
  variant?: "task" | "activation";
  padding?: "none" | "small";
  disabled?: boolean;
  printMode?: boolean;
  grow?: boolean;
};

export function Card(props: CardProps): JSX.Element {
  const { children, padding = "small", variant, printMode, disabled, grow, ...rest } = props;

  return (
    <div
      {...rest}
      className={clsx(
        styles.slobCard,
        variant && styles[variant],
        padding === "small" && "p-32",
        disabled && styles.disabled,
        printMode && styles.printMode,
        grow && styles.grow,
      )}
    >
      {children}
    </div>
  );
}
