import { useSlobQuery } from "client/src/hooks/query";

import type { JsonToTypeMapper } from "client/src/hooks/query";
import type { ClientId } from "shared/types/Client";
import type { ClientFeatureToggles } from "shared/types/Toggles";

export const jsonTogglesToToggles: JsonToTypeMapper<ClientFeatureToggles> = (featureToggles) => {
  return featureToggles;
};

export const useGetFeatureToggles = (clientId?: ClientId) =>
  useSlobQuery<ClientFeatureToggles>({
    method: "get",
    path: `/api/feature-toggles${clientId ? "?clientId=" + clientId : ""}`,
    map: jsonTogglesToToggles,
  });

export function useFeatureToggles(clientId?: ClientId) {
  const { data: featureToggles } = useGetFeatureToggles(clientId);

  if (featureToggles == null) {
    throw new Error(
      `Feature toggles were not found in the React Query Cache.\n\n` +
        //
        `You have to make sure to fetch feature toggles from the network with React Query \n` +
        `using the \`useGetFeatureToggles\` hook further up in the tree, and not render this component \n` +
        `until they are available, so don't forget to handle the loading state of the query properly.\n\n` +
        //
        `If you are in a Storybook story, you have to initialize the query client's cache first.\n` +
        `You can do this with the \`reactQuery\` parameter like this:\n\n` +
        //
        `Story.parameters = {\n` +
        ` reactQuery: (queryClient: QueryClient) =>\n` +
        `   queryClient.setQueryData(["get", "/api/feature-toggles"], getDummyClientFeatureToggles()),\n` +
        `};\n\n` +
        //
        `If you are in a test file, mock the hook like this:\n\n` +
        `jest.mock("client/src/hooks/useFeatureToggles", () => ({\n` +
        `  useFeatureToggles: jest.fn(() => getDummyClientFeatureToggles()),\n` +
        `}));\n\n`,
    );
  }

  return featureToggles;
}
