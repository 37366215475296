import type { english } from "client/src/i18n/locales/en";
import type { spanish } from "client/src/i18n/locales/es";

import type { ValueOf } from "shared/types/Helper";

// union of all supported locales
export type Locale = ValueOf<typeof LocaleKeys>;

export const LocaleKeys = ["en", "es"] as const;

export type BaseLocale = typeof english & typeof spanish;

export const defaultLocale: Locale = LocaleKeys[0];
