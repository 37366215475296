import { Eyebrow } from "client/src/components/Typography/Typography";
import clsx from "clsx";
import React from "react";

import * as styles from "./progressBar.module.less";
import type { ReactNode } from "react";

export type ProgressBarProps = {
  id: string;
  value?: number;
  max?: number;
  bottomText?: ReactNode;
  ["aria-label"]?: string;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  id,
  value,
  max = 100,
  bottomText,
  "aria-label": ariaLabel,
}) => {
  return (
    <div className={clsx(styles.progressBarWrapper, "stack-y-16")}>
      <progress
        id={id}
        value={value}
        max={max}
        aria-valuenow={value}
        aria-valuemin={value === undefined ? undefined : 0}
        aria-valuemax={max}
        aria-valuetext={value !== undefined ? `${value} out of ${max}` : undefined}
        aria-label={ariaLabel}
      />
      {bottomText && (
        <Eyebrow as="label" htmlFor={id}>
          {bottomText}
        </Eyebrow>
      )}
    </div>
  );
};
