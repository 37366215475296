import { useRef, useEffect } from "react";

export function useCloseOnPressEscape(props: {
  popoverVisible: boolean;
  setPopoverVisible: (visible: boolean) => void;
}) {
  const { popoverVisible, setPopoverVisible } = props;

  const setPopoverVisibleRef = useRef<(visible: boolean) => void>(undefined);
  useEffect(() => {
    setPopoverVisibleRef.current = setPopoverVisible;
  }, [setPopoverVisible]);

  const lastOutsideActiveElementRef = useRef<HTMLElement | null>(null);

  // Remember which element was focused before opening the popover
  useEffect(() => {
    if (popoverVisible) {
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- .
      lastOutsideActiveElementRef.current = document.activeElement as HTMLElement;
    } else {
      lastOutsideActiveElementRef.current = null;
    }
  }, [popoverVisible]);

  useEffect(() => {
    if (!popoverVisible) {
      return;
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setPopoverVisibleRef.current?.(false);
        // Re-focus the last element that was active
        // before opening the popover when closing it
        lastOutsideActiveElementRef.current?.focus();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [popoverVisible]);
}
